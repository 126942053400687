import { useCallback, useEffect, useRef, useState } from "react";
import "../AppDC.css";
import Engine, {
  EditMode,
  MovementSensitivity,
} from "@drillflo/drillcore/lib/2d/Engine";
import Drill, { HashType } from "@drillflo/drillcore/lib/Drill";
import ExportEngine from "@drillflo/drillcore/lib/2d/ExportEngine";

import { Rectangle } from "paper";
import Performer, { PerformerSymbol } from "@drillflo/drillcore/lib/Performer";

import React from "react";

import FieldSettingsModal from "./fieldSettingsModal";
import AudioSettingsModal from "./audioSettingsModal.tsx";
import ModeSelectionTool from "./modeSelectControl";
import { useDocumentAccess } from "../services/documentAccess.tsx";

import PerformerModal from "./performerModel";
import PDFExportButton from "./drillPDF";
import CollaboratorButton from "./collaboratorButton";
import LeftNav from "./leftNav";
import Comments from "./comments";
import RightNav from "./rightControlBar";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-hot-toast";
import { analytics, auth, db, getUserPlanData } from "../services/firebase";
import { useNavigate, useParams } from "react-router-dom";
import MarketingNavbar from "./marketingNav";
import LoadingSpinner from "./loadingSpinner";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Timeline from "./elements/Timeline.tsx";
import {
  Command,
  MessageCircleMore,
  Pause,
  Play,
  Redo,
  Ruler,
  Undo,
} from "lucide-react";
import Helmet from "react-helmet";
import Joyride from "react-joyride";
import { ShortcutsList } from "./keyboardShortcuts.tsx";
import convertToMarchingCoordinates from "../hooks/convertToMarchingCoordinate";
import PrintPreview from "./drillView/printPreview.tsx";
import { Printer, ChevronLeft, ChevronRight, X, Download } from "lucide-react";
import { analyzeSpacing } from "../services/spacingHelpers";
import CoordinateModal from "./elements/coordinateModal";
import { DrillColors } from "@drillflo/drillcore/lib/2d/Engine";
import { Color } from "paper";
import { logEvent } from "firebase/analytics";

function FieldEditor2() {
  const { slug } = useParams();

  const canvas = useRef<HTMLCanvasElement>(null);
  const exportCanvas = useRef<HTMLCanvasElement>(null);

  const [drill, setDrill] = useState<Drill | null>(null);

  const [engine, setEngine] = useState<Engine | null>(null);
  const [exportEngine, setExportEngine] = useState<ExportEngine | null>(null);

  const [zoomSpeed, setZoomSpeed] = useState<number>(22);
  const [endzones, setEndzones] = useState<boolean>(false);
  const [hashType, setHashType] = useState<HashType>(HashType.COLLEGE);
  const [snapToSteps, setSnapToSteps] = useState<boolean>(true);
  const [showMovementArrows, setShowMovementArrows] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<string>("PAN");
  const [planVersion, setPlanVersion] = useState(null);
  const [redrawNonce, setRedrawNonce] = useState(0); // "Nonce" to trigger redraws
  const [newPerformerSymbol, setNewPerformerSymbol] = useState<string>("X");
  const [performerPaintStepSeperation, setPerformerPaintStepSeperation] =
    useState<number>(4);

  const [showAudioTrackModal, setShowAudioTrackModal] =
    useState<boolean>(false);
  const [showFeildSettingsModal, setShowFieldSettingsModal] =
    useState<boolean>(false);
  const [showPerformerLabelModal, setShowPerformerLabelModal] =
    useState<boolean>(false);
  const [showCoordinateSheetModal, setShowCoordinateSheetModal] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [modeSelectOpen, setModeSelectOpen] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [commentCounts, setCommentCounts] = useState({});

  const { hasAccess, isLoading, role } = useDocumentAccess(slug || "");

  const [title, setTitle] = useState<string>("Untitled");
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [audio, setAudio] = useState(null);
  const [tempo, setTempo] = useState<number>(120);
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [firebaseLoaded, setFirebaseLoaded] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [collaboratorPermission, setCollaboratorPermission] =
    useState("editor");
  const [savedStatus, setSavedStatus] = useState<boolean>(true);

  const [pagetransitionPercentage, setPageTransitionPercentage] =
    useState<number>(0);
  const [pages, setPages] = useState([]);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);

  const [data, setData] = useState(null);
  const [undoHistory, setUndoHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);

  const [tempoChanges, setTempoChanges] = useState({});

  const [selectedPerformerCoordinate, setSelectedPerformerCoordinate] =
    useState({});

  const [currentCount, setCurrentCount] = useState<number>(0);
  const [isShortcutsOpen, setIsShortcutsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Store current bounds of the screen, which keeps our x,y and width, height
  const [bounds, setBounds] = useState(new Rectangle(0, 0, 1000, 600));

  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth * 0.75);
  const [canvasHeight, setCanvasHeight] = useState(window.innerHeight * 0.65);
  const [showExportDialog, setShowExportDialog] = useState<boolean>(false);
  const [coordinateSheetData, setCoordinateSheetData] = useState<object>({});
  const [collaborators, setCollaborators] = useState([]);

  //
  const animationRef = useRef<number>();
  const lastFrameTimeRef = useRef<number>(0);
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth <= 767) {
      navigate("/mobile-disabled"); // Redirect to a specific page
    }
  }, [navigate]);

  const animate = useCallback(
    (currentTime: number) => {
      if (!isPlaying || !drill) return;

      const deltaTime = lastFrameTimeRef.current
        ? (currentTime - lastFrameTimeRef.current) / 1000
        : 0;
      lastFrameTimeRef.current = currentTime;

      // Access current and next page data
      const currentPageIndex = drill.selectedPageIndex;
      const currentPageData = pages[currentPageIndex];
      const currentPageTempo = currentPageData?.tempo || 120;
      const currentPageCounts = currentPageData?.counts || 8;

      const countsPerSecond = currentPageTempo / 60;
      const countIncrement = deltaTime * countsPerSecond;

      setCurrentCount((prevCount) => {
        const newCount = prevCount + countIncrement;

        if (newCount >= currentPageCounts) {
          const nextPageIndex = currentPageIndex + 1;
          if (nextPageIndex < drill.pages.length) {
            // Disable drawing until the next page is loaded and transitioned
            drill.disableDraw = true;

            // Preload next page asynchronously if needed
            drill.selectPage(nextPageIndex); // Make lightweight
            setCurrentPage(nextPageIndex);
            setPageTransitionPercentage(0);

            drill.disableDraw = false;

            return newCount - currentPageCounts;
          } else {
            // Stop playback
            setIsPlaying(false);
            setCurrentCount(0);
            setPageTransitionPercentage(0);
            if (audioRef.current) {
              audioRef.current.pause();
              audioRef.current.currentTime = 0;
            }
            return 0;
          }
        }

        // Update transition percentage smoothly
        setPageTransitionPercentage((newCount / currentPageCounts) * 100);
        return newCount;
      });

      animationRef.current = requestAnimationFrame(animate);
    },
    [isPlaying, drill, pages]
  );
  useEffect(() => {
    if (isPlaying) {
      lastFrameTimeRef.current = performance.now();
      animationRef.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(animationRef.current);
      if (audioRef.current) {
        audioRef.current.pause();
      }
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, [isPlaying, animate]);
  useEffect(() => {
    if (audioUrl) {
      audioRef.current.src = audioUrl;
      audioRef.current.load();
    }
  }, [audioUrl]);

  const scaleCanvas = useCallback(() => {
    if (!canvas.current) return;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const newCanvasWidth = Math.round(screenWidth * 0.75);
    const newCanvasHeight = Math.round(screenHeight * 0.65);

    canvas.current.width = newCanvasWidth;
    canvas.current.height = newCanvasHeight;

    setCanvasWidth(newCanvasWidth);
    setCanvasHeight(newCanvasHeight);

    // Update bounds to match new canvas size while maintaining scale
    setBounds(new Rectangle(0, 0, newCanvasWidth, newCanvasHeight).scale(0.95));

    // Redraw if engine exists
    if (engine) {
      engine.drawToCanvas(bounds);
    }
  }, [engine, bounds]);

  // Adjust the canvas size on initial load and window resize
  useEffect(() => {
    setBounds(new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95));

    scaleCanvas(); // Initial scaling

    window.addEventListener("resize", scaleCanvas); // Listen for window resize
    return () => {
      window.removeEventListener("resize", scaleCanvas); // Clean up on component unmount
    };
  }, []);

  useEffect(() => {
    scaleCanvas();
    window.addEventListener("resize", scaleCanvas);
    return () => window.removeEventListener("resize", scaleCanvas);
  }, [engine]);
  const checkPlanStatus = async () => {
    if (!role || !auth.currentUser) return;

    try {
      const planData = await getUserPlanData(auth.currentUser.uid);

      // if (!planData || !userData) {
      //   console.error("Plan data or user data is missing");
      //   setLoading(false);
      //   return;
      // }

      if (planData.planActive === false) {
        setPlanVersion("free");
      }
      if (planData.planActive === true) {
        setPlanVersion("pro");
      }
    } catch (error) {
      console.error("Error checking upload status:", error);
    } finally {
    }
  };
  const fetchUserData = async () => {
    setLoading(true);
    // console.log(auth.currentUser.uid);
    try {
      // Query the "users" collection to find the document with the matching uid
      const q = query(
        collection(db, "users"),
        where("uid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If we found a matching document, set the user data
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per uid
        setUserData({ id: userDoc.id, ...userDoc.data() });
      } else {
        console.error("No user data found for the current UID");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!canvas.current) return;
    const DEFAULT_DRILL_COLORS: DrillColors = {
      background: new Color("#ffffff"),
      cells: new Color("#d3fefe"),
      halfYardLines: new Color("#73bcd4"),
      yardLines: new Color("#404040"),
      hashes: new Color("##404040"),
      performers: new Color("#040F16"),
      yardLineLetters: new Color(0, 0.4),
      selectedPerformer: new Color("#4842f5"),
      selectionRectangle: new Color("#4842f5"),
      previousPositionLine: new Color("#ff9e9e"),
      nextPositionLine: new Color("#7edb7d"),
      collision: new Color("#FF0000"),
      performerPaintPath: new Color("#000000"),
    };
    const userColors = DEFAULT_DRILL_COLORS; // Replace with logic to get user preferences

    const engine = new Engine(canvas.current, userColors, endzones);

    engine.hashType = hashType;
    engine.movementSensitivity = snapToSteps
      ? MovementSensitivity.FULL_STEP
      : MovementSensitivity.FREE;
    engine.movementArrows = true;
    engine.newPerformerSymbol = newPerformerSymbol as PerformerSymbol;
    engine.performerPaintStepSeperation = performerPaintStepSeperation;
    const drill = new Drill(engine);
    setEngine(engine);
    setDrill(drill);
    drill.selectPage(0);
    setInitialLoad(true);
    setBounds(new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95));

    // Add event listener for mouse scroll to zoom in and out
    const handleWheel = (event: WheelEvent) => {
      if (!canvas.current) return;

      event.preventDefault();
      // Get the amount of zoom from the wheel event and calculate the scale factor
      const zoom = event.deltaY;
      const scaleAmount = Math.exp(-zoom * zoomSpeed * 0.00001);

      // Get current mouse position relative to the canvas
      const canvasRect = canvas.current.getBoundingClientRect();
      const mouseX = event.clientX - canvasRect.left;
      const mouseY = event.clientY - canvasRect.top;

      // Adjust the bounds to keep the zoom centered at the mouse position
      setBounds((prevBounds) => {
        let newBounds = prevBounds.clone();

        // Calculate the mouse position as a percentage of the bounds
        const mousePercentX = (mouseX - newBounds.x) / newBounds.width;
        const mousePercentY = (mouseY - newBounds.y) / newBounds.height;

        // Scale the bounds
        newBounds = newBounds.scale(scaleAmount);

        // Calculate the new position of the top-left corner to keep the mouse point fixed
        newBounds.x = mouseX - mousePercentX * newBounds.width;
        newBounds.y = mouseY - mousePercentY * newBounds.height;

        return newBounds;
      });
    };

    // Add event listener for click and drag to pan
    let isDragging = false;
    let draggingType:
      | "pan"
      | "moveSelection"
      | "createSelection"
      | "paintPerformers"
      | null = null;
    let mouseMoved = false;
    let startX = 0;
    let startY = 0;
    let mouseDownX = 0;
    let mouseDownY = 0;

    const handleMouseDown = (event: MouseEvent) => {
      if (!canvas.current) return;
      isDragging = true;

      const canvasRect = canvas.current.getBoundingClientRect();

      startX = event.clientX - canvasRect.left;
      startY = event.clientY - canvasRect.top;
      mouseDownX = startX;
      mouseDownY = startY;

      const point = engine.isPointInSelectionRectangle(startX, startY);

      if (point) {
        draggingType = "moveSelection";
        engine.movementOriginPoint = point;
      } else {
        // If edit mode is man, or the middle mouse button is pressed, pan the view
        if (engine.editMode === EditMode.PAN || event.button === 1) {
          draggingType = "pan";
          event.preventDefault();
        } else if (engine.editMode === EditMode.SELECT_AND_MOVE) {
          draggingType = "createSelection";
          engine.startSelection(startX, startY);
        } else if (engine.editMode === EditMode.PAINT_PERFORMERS) {
          draggingType = "paintPerformers";
          engine.startPerformerPainting(startX, startY);
        }
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!canvas.current || !isDragging) return;

      const canvasRect = canvas.current.getBoundingClientRect();

      // Calculate the new mouse position relative to the canvas
      const currentX = event.clientX - canvasRect.left;
      const currentY = event.clientY - canvasRect.top;

      // Calculate how much the mouse moved since the last position
      const dx = currentX - startX;
      const dy = currentY - startY;

      const dxFromMouseDown = currentX - mouseDownX;
      const dyFromMouseDown = currentY - mouseDownY;

      // Check if drag
      if (
        !mouseMoved &&
        (Math.abs(dxFromMouseDown) > 0.25 || Math.abs(dyFromMouseDown) > 0.25)
      ) {
        mouseMoved = true;
      }

      if (mouseMoved) {
        if (draggingType === "moveSelection") {
          engine.moveSelectedPerformers(currentX, currentY);
          setRedrawNonce((prevNonce) => prevNonce + 1);
        } else if (draggingType === "createSelection") {
          engine.updateSelection(currentX, currentY);
          setRedrawNonce((prevNonce) => prevNonce + 1);
        } else if (draggingType === "pan") {
          // Update the bounds using the delta
          setBounds((prevBounds) => {
            const newBounds = prevBounds.clone();
            newBounds.x += dx;
            newBounds.y += dy;
            return newBounds;
          });
        } else if (draggingType === "paintPerformers") {
          engine.updatePerformerPainting(currentX, currentY);
          setRedrawNonce((prevNonce) => prevNonce + 1);
        }
      }

      // Update the starting position for the next move
      startX = currentX;
      startY = currentY;
    };

    const handleMouseUp = (event: MouseEvent) => {
      // If the mouse didn't move, consider it a click
      if (!mouseMoved && engine) {
        // Get the mouse position relative to the canvas
        const canvasRect = canvas.current?.getBoundingClientRect();
        const x = event.clientX - canvasRect!.left;
        const y = event.clientY - canvasRect!.top;

        // Ignore middle mouse button clicks
        if (event.button === 1) return;

        engine.handleClick(x, y);
        setRedrawNonce((prevNonce) => prevNonce + 1);
      }

      if (draggingType === "createSelection" && mouseMoved) {
        engine.endSelection();
        setRedrawNonce((prevNonce) => prevNonce + 1);
      }

      if (draggingType === "paintPerformers" && mouseMoved) {
        engine.endPerformerPainting(drill);

        setRedrawNonce((prevNonce) => prevNonce + 1);
      }

      isDragging = false;
      mouseMoved = false;
    };

    canvas.current.addEventListener("wheel", handleWheel);
    canvas.current.addEventListener("mousedown", handleMouseDown);
    canvas.current.addEventListener("mousemove", handleMouseMove);
    canvas.current.addEventListener("mouseup", handleMouseUp);
    setInitialLoad(true);
    setIsInitialized(true); // Mark initialization as complete
    // Cleanup event listeners on component unmount
    return () => {
      canvas.current?.removeEventListener("wheel", handleWheel);
      canvas.current?.removeEventListener("mousedown", handleMouseDown);
      canvas.current?.removeEventListener("mousemove", handleMouseMove);
      canvas.current?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [canvas.current, zoomSpeed]);

  // Whenever the bounds or nonce changes, we can update the engine's bounds
  useEffect(() => {
    if (engine && canvas.current) {
      engine.drawToCanvas(bounds);
    }
  }, [bounds, redrawNonce]);

  const updatePages = (newPages) => {
    setPages(newPages); // Update state
  };

  useEffect(() => {
    const loadData = async () => {
      if (!isInitialized || !engine || !initialLoad) return;
      // setLoading(true);
      try {
        const docRef = doc(db, "designs", slug);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const designData = docSnap.data();
          const drillUnpack = await designData.serializedDrill;

          if (drillUnpack) {
            const parsedData = JSON.parse(drillUnpack);
            if (!parsedData.displayOptions) {
              setFirebaseLoaded(false);
              return;
            }
            const drill = Drill.load(parsedData, engine);
            drill.selectPage(currentPage);
            drill.draw();
            engine.drawToCanvas(bounds);

            setBounds(
              new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95)
            );

            setDrill(drill);
            setTitle(designData.title || "Untitled");
            drill.title = designData.title;
            setEndzones(parsedData.displayOptions.endZones);
            setShowMovementArrows(true);
            setHashType(parsedData.displayOptions.hashType);
            setUndoHistory(designData.history);
            setRedoHistory(designData.redoHistory);
            setSnapToSteps(
              designData.snapToGrid !== undefined ? designData.snapToGrid : true
            );
            setData(designData);
            drill.draw();

            setAudioUrl(designData.audioFile || "");
            // setTempo(designData.pages[drill?.selectedPageIndex].tempo ?? 120);
            setPages(designData.pages);
            setFirebaseLoaded(true);
            drill.selectPage(0);
          } else if (!drillUnpack) {
            // const drill = new Drill(engine);
            // setDrill(drill);
            // drill.selectPage(1);
            // drill.draw();
            // setBounds(
            //   new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95)
            // );
            setFirebaseLoaded(true);
          }
        }
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [isInitialized, engine, initialLoad, slug]);

  // Load the data from Firebase at initial load of the screen
  useEffect(() => {
    if (!engine || initialLoad === false) return;

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData();

        if (!role || !auth.currentUser) return;
        setLoading(true);
      } else {
        setCurrentUser(null);
      }
    });
    return () => unsubscribe();
  }, [slug, initialLoad]);

  // Whenever the endzones state changes, we can update the drill's endzones
  useEffect(() => {
    if (engine) {
      engine.endZones = endzones;
      engine.hashType = hashType;
      engine.movementSensitivity = snapToSteps
        ? MovementSensitivity.FULL_STEP
        : MovementSensitivity.FREE;
      engine.movementArrows = showMovementArrows;
      engine.newPerformerSymbol = newPerformerSymbol as PerformerSymbol;
      engine.performerPaintStepSeperation = performerPaintStepSeperation;
      drill?.draw();
      engine.drawToCanvas(bounds);
    }
  }, [
    endzones,
    hashType,
    snapToSteps,
    showMovementArrows,
    newPerformerSymbol,
    performerPaintStepSeperation,
  ]);

  // Whenever the edit mode changes, we can update the engine's edit mode
  useEffect(() => {
    if (engine) {
      engine.editMode = EditMode[editMode as keyof typeof EditMode];
    }
  }, [editMode, engine]);

  function print() {
    if (!drill || !engine) return;
    if (!showExportDialog) {
      setShowExportDialog(true);
      return;
    }
  }
  useEffect(() => {
    if (!drill || !exportCanvas.current) return;

    // Ensure the canvas width and height are set and match
    // the CSS width and height
    const dpr = window.devicePixelRatio || 1;
    exportCanvas.current.width = exportCanvas.current.clientWidth / dpr;
    exportCanvas.current.height = exportCanvas.current.clientHeight / dpr;

    // Create a new ExportEngine instance, regardless of whether it already exists
    const exportEngine = new ExportEngine(
      drill,
      exportCanvas.current,
      undefined,
      endzones,
      hashType
    );
    setExportEngine(exportEngine);
  }, [showExportDialog]);
  // Audio Functions
  const handleAddPerformer = async () => {
    if (!drill) return;
    const performer = new Performer(drill, "O1", 1, "C");
    drill.addPerformer(performer);
    setRedrawNonce((prevNonce) => prevNonce + 1);
  };
  const handleAddPerformers = async (numOfPerformers) => {
    if (!drill) return;

    for (let i = 0; i < numOfPerformers; i++) {
      const performer = new Performer(drill, "O1", i + 1, "C");
      drill.addPerformer(performer);
    }

    setRedrawNonce((prevNonce) => prevNonce + 1);
  };

  const handleAddPage = async () => {
    drill?.addPage();
    const newPage = { counts: 8, tempo: 120 };
    setPages((prevPages) => [...prevPages, newPage]);
    setRedrawNonce((prevNonce) => prevNonce + 1);
  };
  const handleAudioUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Create a local URL for immediate playback
        const localUrl = URL.createObjectURL(file);

        setAudio(file);

        // Upload file to Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `audio/${slug}/${file.name}`);
        await uploadBytes(storageRef, file);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        // Update Firestore document
        const docRef = doc(db, "designs", slug);
        await updateDoc(docRef, {
          audioFile: downloadURL,
          audioFileName: file.name,
          updated: new Date(),
        });
        setAudioUrl(downloadURL);
        console.log("Audio file uploaded and document updated successfully");
      } catch (error) {
        console.error("Error uploading audio file:", error);
        toast.error("There was an Error uploading your audio file");
      }
    }
  };

  const updateFirebaseDocument = useCallback(async () => {
    if (
      !drill ||
      initialLoad === false ||
      firebaseLoaded === false ||
      !engine ||
      collaboratorPermission === "viewer"
    ) {
      console.error(
        "Stuff isn't ready to save yet " + firebaseLoaded + initialLoad
      );
      return;
    }

    console.log("unserialized ", drill);

    const serializedDrill = drill.serialize();
    console.log("Serialized ", serializedDrill);
    const docRef = doc(db, "designs", slug);

    try {
      // Fetch the current document to get the existing history
      const docSnap = await getDoc(docRef);
      let history = [];

      if (docSnap.exists()) {
        history = docSnap.data().history || [];
      }

      // Add the new serialized drill to the history
      history.push(JSON.stringify(serializedDrill));

      // Limit the history to the last 25 entries
      if (history.length > 20) {
        history = history.slice(history.length - 20);
      }

      const canvas = document.getElementById("drillCanvas"); // Replace with your actual canvas ID
      const canvasBlob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/png")
      );
      const storage = getStorage();

      if (canvasBlob) {
        // Upload the image to Firebase Storage
        const storageRef = ref(storage, `snapshots/${slug}.png`);
        const snapshot = await uploadBytes(storageRef, canvasBlob);
        const snapshotURL = await getDownloadURL(snapshot.ref);

        // Update the Firebase document with the new snapshot URL and other data
        await updateDoc(docRef, {
          title: title,
          hashMarkType: engine?.hashType,
          showEndzone: endzones,
          drillOptions: serializedDrill.displayOptions,
          snapToGrid: snapToSteps,
          updated: Timestamp.now(),
          serializedDrill: JSON.stringify(serializedDrill),
          history, // Store the updated history
          pages,
          // redoHistory: redoHistory,
          snapshotURL, // Save the snapshot URL
        });

        console.log("Snapshot saved and Firebase document updated!");
        // toast("Save");
      } else {
        console.error("Failed to generate canvas snapshot");
        toast.error("Failed to save");
      }

      setUndoHistory(history);
      const result = processDrillData(serializedDrill);

      // Render the result in designed pages
      setCoordinateSheetData(result);
    } catch (error) {
      console.error("Error updating document or subcollections: ", error);
    }
  }, [
    slug,
    title,
    drill,
    engine,
    endzones,
    snapToSteps,
    pages,
    redoHistory,
    undoHistory,
  ]);

  useEffect(() => {
    // Debounce the update to avoid too many writes
    setSavedStatus(false);
    // if (firebaseLoaded == false || initialLoad === false) return fetchData;

    const debounceTimer = setTimeout(() => {
      updateFirebaseDocument();
      setSavedStatus(true);
    }, 2000); // Wait for 2 seconds of inactivity before updating
    return () => clearTimeout(debounceTimer);
  }, [title, tempo, drill, engine, redrawNonce, pages]);

  const handleKeyDown = (e) => {
    console.log(e.key, e.ctrlKey, e.metaKey); // Log key and modifiers

    const targetTag = e.target.tagName.toLowerCase();
    const isInputField =
      targetTag === "input" ||
      targetTag === "textarea" ||
      e.target.isContentEditable;

    if (isInputField) {
      // Allow native functionality for text inputs or editable fields
      return;
    }

    const isCtrlOrCmd = e.ctrlKey || e.metaKey;

    if (isCtrlOrCmd && e.key === "z") {
      if (e.shiftKey) {
        // Redo: Ctrl + Shift + Z or Cmd + Shift + Z
        e.preventDefault(); // Prevent browser's redo
        redoChange();
      } else {
        // Undo: Ctrl + Z or Cmd + Z
        e.preventDefault(); // Prevent browser's undo
        undoChange();
      }
    }
    // Other key bindings
    if (e.key === "1" && collaboratorPermission !== "viewer") {
      setEditMode("PAN");
    }
    if (e.key === "2" && collaboratorPermission !== "viewer") {
      setEditMode("SELECT_AND_MOVE");
    }
    if (e.key === "3" && collaboratorPermission !== "viewer") {
      setEditMode("PAINT_PERFORMERS");
    }
    if (e.key === "4") {
      setEditMode("comment");
    }
    if (e.key === "[") {
      handlePageChange(1);
    }
    if (e.key === "]") {
      handlePageChange(2);
    }
    if (e.key === "z") {
      undoChange();
    }
    if (e.key === "p") {
      isPlaying === true ? handleStop() : handlePlay();
    }
    if (e.key === "Enter" && isEditing === true) {
      setIsEditing(false);
      updateFirebaseDocument();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    if (engine) {
      engine.pageTransitionPercentage = pagetransitionPercentage;
      setRedrawNonce((prevNonce) => prevNonce + 1);
    }
  }, [pagetransitionPercentage, engine]);

  useEffect(() => {
    if (!role) return;

    if (role === "viewer") {
      return setCollaboratorPermission("viewer"), setEditMode("comment");
    }
  }, [role]);

  useEffect(() => {
    checkPlanStatus();
  }, [auth, userData]);

  const undoChange = () => {
    if (!undoHistory || undoHistory.length === 0 || !engine || !drill) {
      console.warn("No history to undo");
      return;
    }

    const currentDrill = drill.serialize();

    setUndoHistory((prevHistory) => {
      const history = prevHistory;
      const lastState = prevHistory[prevHistory.length - 1]; // Get last state
      console.log(lastState);

      // Handle deserialization if needed
      if (lastState) {
        const parsedData = JSON.parse(lastState);

        const drill = Drill.load(parsedData, engine);
        drill.selectPage(currentPage);

        drill.draw();
        engine.drawToCanvas(bounds);
        setBounds(new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95));
        setRedoHistory((prevRedo) => [
          ...(Array.isArray(prevRedo) ? prevRedo : []), // Ensure prevRedo is an array
          JSON.stringify(currentDrill),
        ]);
      }

      return prevHistory.slice(0, -1); // Remove the last state from history
    });
  };

  const redoChange = () => {
    if (!redoHistory || redoHistory.length === 0 || !engine || !drill) {
      console.warn("No history to redo");
      return;
    }

    const currentDrill = drill.serialize();

    setRedoHistory((prevRedo) => {
      const history = prevRedo;
      const lastState = prevRedo[prevRedo.length - 1]; // Get last state
      console.log(lastState);

      // Handle deserialization if needed
      if (lastState) {
        const parsedData = JSON.parse(lastState);

        const drill = Drill.load(parsedData, engine);
        drill.selectPage(currentPage);

        drill.draw();
        engine.drawToCanvas(bounds);
        setBounds(new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95));

        // Add the current state to undoHistory
        setUndoHistory((prevUndo) => [
          ...prevUndo,
          JSON.stringify(currentDrill),
        ]);
      }

      return history.slice(0, -1); // Remove the last state from redoHistory
    });
  };

  useEffect(() => {
    const fetchCommentCounts = async () => {
      const commentsRef = collection(db, "comments");
      const q = query(commentsRef, where("designId", "==", slug));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const counts = {};
        snapshot.docs.forEach((doc) => {
          const comment = doc.data();
          counts[comment.pageNumber] = (counts[comment.pageNumber] || 0) + 1;
        });
        setCommentCounts(counts);
      });

      return () => unsubscribe();
    };

    fetchCommentCounts();
  }, [slug]);
  // Animation and Playing

  const handlePageChange = (index: number) => {
    console.log("Changing tempo");
    if (drill === null) return console.error("Can't change tempo");
    drill.selectPage(index);
    setRedrawNonce((prevNonce) => prevNonce + 1);
    setCurrentCount(0);
    setCurrentPage(index);
    if (tempoChanges[index]) {
      setTempo(tempoChanges[index]);
    }
  };
  const handleCountChangeForPage = (pageIndex: number, newCounts: number) => {
    const counts = newCounts || 0;
    updatePages((prevPages) => {
      const newPages = [...prevPages];
      newPages[pageIndex] = { ...newPages[pageIndex], counts };
      return newPages;
    });
  };

  const handleTempoChangeForPage = (pageIndex: number, newTempo: number) => {
    try {
      setTempoChanges((prev) => ({
        ...prev,
        [pageIndex]: newTempo,
      }));
      if (drill?.selectedPageIndex === pageIndex) {
        setTempo(newTempo);
      }

      // Update pages array
      setPages((prevPages) =>
        prevPages.map((page, idx) =>
          idx === pageIndex ? { ...page, tempo: newTempo } : page
        )
      );
    } catch (error) {
      console.error("Error changing tempo", error);
    }
  };
  const handlePlay = () => {
    if (!drill) return toast.error("Something went wrong");
    setIsPlaying(true);
    setCurrentCount(0);
    lastFrameTimeRef.current = 0;

    // Calculate time offset for audio based on current position in the show
    let totalTimeBeforeCurrentPage = 0;
    for (let i = 0; i < drill.selectedPageIndex; i++) {
      const pageTempo = pages[i].tempo || 120;
      const pageTime = ((pages[i].counts - 1) / pageTempo) * 60;
      totalTimeBeforeCurrentPage += pageTime;
    }

    if (audioRef.current && audioUrl) {
      audioRef.current.currentTime = totalTimeBeforeCurrentPage;
      audioRef.current.play();
    }

    animationRef.current = requestAnimationFrame(animate);

    logEvent(analytics, "play_drill_document", {
      played_at: Timestamp.now().toDate().toISOString(), // Log as ISO string for consistency
      page_viewed: "/designs",
      user: userData?.id,
      drill_doc: slug,
      device_type: navigator.userAgent.includes("Mobi") ? "mobile" : "desktop",
    });
  };

  const handleStop = () => {
    setIsPlaying(false);
    setCurrentCount(0);
    setPageTransitionPercentage(0);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    logEvent(analytics, "stop_drill_document", {
      stopped_at: Timestamp.now().toDate().toISOString(), // Log as ISO string for consistency
      page_viewed: "/designs",
      user: userData?.id,
      drill_doc: slug,
      device_type: navigator.userAgent.includes("Mobi") ? "mobile" : "desktop",
    });
  };

  useEffect(() => {
    if (!isPlaying && animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isPlaying]);
  useEffect(() => {
    // Check local storage for the tutorial flag
    const tutorialSeen = localStorage.getItem("tutorial1Seen");
    if (!tutorialSeen) {
      setShowTutorial(true); // Show tutorial if flag not found
    }
  }, []);

  const handleCloseTutorial = () => {
    // Set flag in local storage so it doesn't show again
    localStorage.setItem("tutorial1Seen", "true");
    setShowTutorial(false); // Hide the tutorial
  };

  // const coordsBack = convertToMarchingCoordinates(-28, 8);
  // setSelectedPerformerCoordinate(coordsBack);

  useEffect(() => {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0 ||
      drill.engine._selectedPerformers.length > 1
    ) {
      return setSelectedPerformerCoordinate({});
    }

    const performerCoords = drill.engine._selectedPerformers[0].position;
    const coordsBack = convertToMarchingCoordinates(
      performerCoords.horizontalStepsFrom50,
      performerCoords.verticalStepsFromCenter
    );
    setSelectedPerformerCoordinate(coordsBack);
  }, [redrawNonce]);

  // Performer Adjustment and Manipulation tools
  function applyEvenSpacing(desiredSpacing) {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;

    // Get the current page's performer positions
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;

    const pagePerformers = currentPage.performerPositions;
    const selectedPerformers = drill.engine._selectedPerformers;

    if (selectedPerformers.length < 2) return;

    // Get current positions of selected performers
    const positions = selectedPerformers
      .map((index) => pagePerformers.get(index))
      .filter((pos): pos is NonNullable<typeof pos> => pos !== undefined);

    // Analyze current arrangement
    const analysis = analyzeSpacing(positions);

    if (analysis.direction === "horizontal") {
      applyHorizontalSpacing(
        selectedPerformers,
        pagePerformers,
        desiredSpacing
      );
    } else {
      applyVerticalSpacing(selectedPerformers, pagePerformers, desiredSpacing);
    }

    drill.draw();
    // setRedrawNonce();
  }

  function applyHorizontalSpacing(
    selectedPerformers: number[],
    pagePerformers: Map<number, any>,
    spacing: number
  ): void {
    // Sort performers by current horizontal position
    const sortedPerformers = [...selectedPerformers].sort((a, b) => {
      const posA = pagePerformers.get(a)?.horizontalStepsFrom50 ?? 0;
      const posB = pagePerformers.get(b)?.horizontalStepsFrom50 ?? 0;
      return posA - posB;
    });

    // Get leftmost position as anchor
    const startPos =
      pagePerformers.get(sortedPerformers[0])?.horizontalStepsFrom50 ?? 0;

    // Apply new spacing
    sortedPerformers.forEach((performerIndex, i) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        pagePerformers.set(performerIndex, {
          ...position,
          horizontalStepsFrom50: startPos + i * spacing,
        });
      }
    });
  }

  function applyVerticalSpacing(
    selectedPerformers: number[],
    pagePerformers: Map<number, any>,
    spacing: number
  ): void {
    // Sort performers by current vertical position
    const sortedPerformers = [...selectedPerformers].sort((a, b) => {
      const posA = pagePerformers.get(a)?.verticalStepsFromCenter ?? 0;
      const posB = pagePerformers.get(b)?.verticalStepsFromCenter ?? 0;
      return posA - posB;
    });

    // Get topmost position as anchor
    const startPos =
      pagePerformers.get(sortedPerformers[0])?.verticalStepsFromCenter ?? 0;

    // Apply new spacing
    sortedPerformers.forEach((performerIndex, i) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        pagePerformers.set(performerIndex, {
          ...position,
          verticalStepsFromCenter: startPos + i * spacing,
        });
      }
    });
  }

  const calculateCenter = (positions, selectedPerformers) => {
    console.log("Positions Map:", Array.from(positions.entries()));
    console.log("Selected Performers:", selectedPerformers);

    const validPositions = selectedPerformers
      .map((index) => {
        const position = positions.get(index);
        if (position) {
          return {
            horizontalStepsFromCenter:
              Number(position.horizontalStepsFrom50) || 0,
            verticalStepsFromCenter:
              Number(position.verticalStepsFromCenter) || 0,
          };
        }
        return undefined;
      })
      .filter((pos) => pos !== undefined);

    console.log("Valid Positions:", validPositions);

    if (validPositions.length === 0) {
      console.warn("No valid positions found. Returning default center.");
      return { horizontalStepsFromCenter: 0, verticalStepsFromCenter: 0 };
    }

    const sumX = validPositions.reduce(
      (sum, pos) =>
        sum +
        (isNaN(pos.horizontalStepsFromCenter)
          ? 0
          : pos.horizontalStepsFromCenter),
      0
    );
    const sumY = validPositions.reduce(
      (sum, pos) =>
        sum +
        (isNaN(pos.verticalStepsFromCenter) ? 0 : pos.verticalStepsFromCenter),
      0
    );

    console.log("SumX:", sumX, "SumY:", sumY);

    return {
      horizontalStepsFromCenter: sumX / validPositions.length,
      verticalStepsFromCenter: sumY / validPositions.length,
    };
  };

  // Rotate a point around a center by given angle in radians
  const rotatePoint = (point, center, angleRadians) => {
    const dx = point.horizontalStepsFrom50 - center.horizontalStepsFromCenter;
    const dy = point.verticalStepsFromCenter - center.verticalStepsFromCenter;
    console.log("New is ", dx, dy);
    const cos = Math.cos(angleRadians);
    const sin = Math.sin(angleRadians);

    return {
      horizontalStepsFrom50:
        center.horizontalStepsFromCenter + (dx * cos - dy * sin),
      verticalStepsFromCenter:
        center.verticalStepsFromCenter + (dx * sin + dy * cos),
    };
  };

  const rotatePerformers = (drill: any, clockwise: boolean) => {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;
    console.log("rotating");
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;
    const pagePerformers = currentPage.performerPositions;

    // Calculate center of selected performers
    const center = calculateCenter(
      pagePerformers,
      drill.engine._selectedPerformers
    );
    console.log("Center", center);

    // 45 degrees in radians
    const angleRadians = (Math.PI / 4) * (clockwise ? -1 : 1);
    console.log(angleRadians);
    // Rotate each selected performer
    drill.engine._selectedPerformers.forEach((performerIndex) => {
      const position = pagePerformers.get(performerIndex);
      console.log("Position is ", position);
      if (position) {
        const rotated = rotatePoint(position, center, angleRadians);
        console.log("Rotation Would Be", rotated);
        pagePerformers.set(performerIndex, rotated);
      }
    });

    drill.draw();
  };

  const rotatePerformersClockwise = (drill: any) => {
    rotatePerformers(drill, true);
  };

  const rotatePerformersCounterClockwise = (drill: any) => {
    rotatePerformers(drill, false);
  };
  const handleRotateClockwise = () => {
    rotatePerformersClockwise(drill);
  };

  const handleRotateCounterClockwise = () => {
    rotatePerformersCounterClockwise(drill);
  };
  const alignPerformersToLeft = () => {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;

    // Get the current page's performer positions
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;
    const pagePerformers = currentPage.performerPositions;

    // Find the minimum horizontal position of the selected performers
    const minX = drill.engine._selectedPerformers
      .map((performerIndex) => {
        const position = pagePerformers.get(performerIndex);
        return position ? position.horizontalStepsFrom50 : Infinity;
      })
      .reduce((min, x) => Math.min(min, x), Infinity);

    if (minX === Infinity) return;

    // Align selected performers to the minimum x position
    drill.engine._selectedPerformers.forEach((performerIndex) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        // Create a new position object with updated horizontalStepsFrom50
        const updatedPosition = {
          ...position,
          horizontalStepsFrom50: minX,
        };
        // Update the position in the Map
        pagePerformers.set(performerIndex, updatedPosition);
      }
    });
    drill.draw();

    // Trigger a redraw if needed
    if (drill) {
      drill.draw();
    }
  };
  const alignPerformersToTop = () => {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;

    // Get the current page's performer positions
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;
    const pagePerformers = currentPage.performerPositions;

    const maxY = drill.engine._selectedPerformers
      .map((performerIndex) => {
        const position = pagePerformers.get(performerIndex);
        return position ? position.verticalStepsFromCenter : -Infinity;
      })
      .reduce((max, y) => Math.max(max, y), -Infinity);

    if (maxY === -Infinity) return;

    // Align selected performers to the top position
    drill.engine._selectedPerformers.forEach((performerIndex) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        pagePerformers.set(performerIndex, {
          ...position,
          verticalStepsFromCenter: maxY,
        });
      }
    });

    drill.draw();

    // Trigger a redraw if needed
  };
  const alignPerformersHorizontally = () => {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;

    // Get the current page's performer positions
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;
    const pagePerformers = currentPage.performerPositions;

    // Calculate the average vertical position
    const verticalPositions = drill.engine._selectedPerformers
      .map((performerIndex) => {
        const position = pagePerformers.get(performerIndex);
        return position ? position.verticalStepsFromCenter : null;
      })
      .filter((y): y is number => y !== null);

    if (verticalPositions.length === 0) return;

    const averageY =
      verticalPositions.reduce((sum, y) => sum + y, 0) /
      verticalPositions.length;

    // Align all selected performers to the average vertical position
    drill.engine._selectedPerformers.forEach((performerIndex) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        pagePerformers.set(performerIndex, {
          ...position,
          verticalStepsFromCenter: averageY,
        });
      }
    });

    drill.draw();
  };
  const alignPerformersToBottom = () => {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;

    // Get the current page's performer positions
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;
    const pagePerformers = currentPage.performerPositions;

    // Find the minimum horizontal position of the selected performers
    const maxY = drill.engine._selectedPerformers
      .map((performerIndex) => {
        const position = pagePerformers.get(performerIndex);
        return position ? position.verticalStepsFromCenter : Infinity;
      })
      .reduce((max, y) => Math.min(max, y), Infinity);

    if (maxY === Infinity) return;

    // Align selected performers to the minimum x position
    drill.engine._selectedPerformers.forEach((performerIndex) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        // Create a new position object with updated horizontalStepsFrom50
        const updatedPosition = {
          ...position,
          verticalStepsFromCenter: maxY,
        };
        // Update the position in the Map
        pagePerformers.set(performerIndex, updatedPosition);
      }
    });
    drill.draw();

    // Trigger a redraw if needed
  };
  const alignPerformersToCenter = () => {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;

    // Get the current page's performer positions
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;
    const pagePerformers = currentPage.performerPositions;

    // Calculate the average horizontal position
    const horizontalPositions = drill.engine._selectedPerformers
      .map((performerIndex) => {
        const position = pagePerformers.get(performerIndex);
        return position ? position.horizontalStepsFrom50 : null;
      })
      .filter((x): x is number => x !== null);

    console.log(horizontalPositions);
    if (horizontalPositions.length === 0) return;

    const averageX =
      horizontalPositions.reduce((sum, x) => sum + x, 0) /
      horizontalPositions.length;

    // Align all selected performers to the average vertical position
    drill.engine._selectedPerformers.forEach((performerIndex) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        pagePerformers.set(performerIndex, {
          ...position,
          horizontalStepsFrom50: averageX,
        });
      }
    });

    drill.draw();
  };

  const alignPerformersToRight = () => {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;

    // Get the current page's performer positions
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;
    const pagePerformers = currentPage.performerPositions;

    // Find the maximum horizontal position of the selected performers
    const maxX = drill.engine._selectedPerformers
      .map((performerIndex) => {
        const position = pagePerformers.get(performerIndex);
        return position ? position.horizontalStepsFrom50 : -Infinity;
      })
      .reduce((max, x) => Math.max(max, x), -Infinity);

    if (maxX === -Infinity) return;

    // Align selected performers to the maximum x position
    drill.engine._selectedPerformers.forEach((performerIndex) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        pagePerformers.set(performerIndex, {
          ...position,
          horizontalStepsFrom50: maxX,
        });
      }
    });

    drill.draw();
    drill.engine._selectedPerformers = [];
  };

  useEffect(() => {
    if (!drill || !pages || drill.selectedPageIndex == null) return;

    // Update the customMessage property for the selected page
    drill.selectedPage.customMessage = `Move ${
      pages[drill.selectedPageIndex]?.counts || 0
    } \n Tempo ${pages[drill.selectedPageIndex]?.tempo || 120}`;

    // Ensure this side effect happens properly without returning prematurely
  }, [redrawNonce, drill, pages, drill?.selectedPageIndex]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem("tutorial1Seen", "true");
      setShowTutorial(false); // Tutorial completed or skipped
    }
  };

  function processDrillData(drillData) {
    const performerCoordinates = [];

    // Loop through each page and process performer positions
    drillData.pages.forEach((page, pageIndex) => {
      page.performerPositions.forEach(({ performerId, position }) => {
        // Find the performer in the array
        let performer = performerCoordinates.find(
          (entry) => entry.performerId === performerId
        );

        // If the performer is not already in the array, add them
        if (!performer) {
          performer = { performerId, coordinates: [] };
          performerCoordinates.push(performer);
        }

        // Convert to marching coordinates
        const marchingCoordinates = convertToMarchingCoordinates(
          position.horizontalStepsFrom50,
          position.verticalStepsFromCenter
        );

        // Store the coordinates along with the page number
        performer.coordinates.push({
          page: pageIndex + 1,
          ...marchingCoordinates,
        });
      });
    });

    const performersById = Object.fromEntries(
      drillData.performers.map((performer) => [performer.id, performer.name])
    );

    performerCoordinates.sort((a, b) => {
      const nameA = performersById[a.performerId] || "";
      const nameB = performersById[b.performerId] || "";
      return nameA.localeCompare(nameB);
    });

    return performerCoordinates;
  }

  const onboardingTourSteps = [
    {
      target: "body",
      content: (
        <div className="text-center">
          <h3 className="font-bold text-2xl">Welcome to DrillFlo!</h3>
          <p className="mt-2">
            Take this short tour to get familiar with the tools and features.
          </p>
        </div>
      ),
      placement: "center",
    },
    {
      target: ".mode-selection-tool-dropdown",
      content: (
        <div>
          <h3 className="font-bold text-lg">Mode Selection Tool</h3>
          <p className="mt-1">
            Use this tool to change the function of your mouse, such as
            selecting, drawing, or moving performers.
          </p>
        </div>
      ),
    },
    {
      target: ".document-pages-row",
      content: (
        <div>
          <h3 className="font-bold text-lg">Project Pages</h3>
          <p className="mt-1">
            View all pages in your project and their details. Click on a page to
            edit or navigate to it.
          </p>
        </div>
      ),
    },
    {
      target: ".add-new-page-button",
      content: (
        <div>
          <h3 className="font-bold text-lg">Add New Pages</h3>
          <p className="mt-1">
            Add a new page to your drill project. Each page represents a
            customizable part of your drill.
          </p>
        </div>
      ),
    },
    {
      target: ".performer-modal-open-button",
      content: (
        <div>
          <h3 className="font-bold text-lg">Add Performers</h3>
          <p className="mt-1">
            Use this tool to add performers to your drill. Performers are the
            key elements that you’ll position and move throughout your design.
          </p>
        </div>
      ),
    },
    {
      target: ".current-page-counts-control",
      content: (
        <div>
          <h3 className="font-bold text-lg">Page Counts</h3>
          <p className="mt-1">
            Adjust the number of counts in the current page. Counts control the
            timing and movement in your drill design.
          </p>
        </div>
      ),
    },
    {
      target: ".audio-modal-open-button",
      content: (
        <div>
          <h3 className="font-bold text-lg">Add/Sync Audio</h3>
          <p className="mt-1">
            Upload an audio file to accompany your drill and synchronize it with
            your project to time movements perfectly.
          </p>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <div className="text-center">
          <h3 className="font-bold text-2xl">You're all set!</h3>
          <p className="mt-2">
            Start designing your drill and bring your vision to life. Have fun!
          </p>
        </div>
      ),
      placement: "center",
    },
  ];

  if (loading)
    return (
      <>
        {" "}
        <MarketingNavbar />
        <LoadingSpinner />
      </>
    );
  if (!hasAccess && !isLoading) {
    return navigate("/access-denied");
  }

  return (
    <>
      <Helmet>
        <title>{title} - DrillFlo Project</title>
      </Helmet>
      <Joyride
        steps={onboardingTourSteps}
        continuous={true}
        run={showTutorial}
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: "#0000ff",
            primaryColor: "#0000ff",
          },
        }}
      />
      <div className=" h-full bg-white max-h-full w-full">
        {showAudioTrackModal && (
          <>
            <AudioSettingsModal
              audioUrl={audioUrl}
              handleAudioUpload={handleAudioUpload}
              showAudioTrackModal={showAudioTrackModal}
              slug={slug}
              setAudioUrl={setAudioUrl}
              setShowAudioTrackModal={setShowAudioTrackModal}
            />
          </>
        )}
        {showFeildSettingsModal && (
          <FieldSettingsModal
            fieldColor={null}
            setFieldColor={null}
            hashMarkType={hashType}
            setHashMarkType={setHashType}
            onClose={() => setShowFieldSettingsModal(false)}
            performerLabelLocation={null}
            setPerformerLabelLocation={null}
            showEndzone={endzones}
            setShowEndzone={setEndzones}
          />
        )}
        {showPerformerLabelModal && (
          <PerformerModal
            drill={drill}
            setRedrawNonce={setRedrawNonce}
            onClose={() => setShowPerformerLabelModal(false)}
            planVersion={planVersion}
          />
        )}
        {showCoordinateSheetModal && (
          <CoordinateModal
            drill={drill}
            onClose={() => setShowCoordinateSheetModal(false)}
            coordinateSheetData={coordinateSheetData}
          />
        )}
        {showExportDialog && (
          <PrintPreview
            exportCanvas={exportCanvas}
            setShowExportDialog={setShowExportDialog}
            exportEngine={exportEngine}
          />
        )}
        <nav className="bg-gradient-to-r from-blue-500 to-purple-600 shadow-md mb-4">
          <div className="max-w-screen-xl flex flex-wrap items-center gap-12 mx-auto p-2 align-middle my-auto h-full">
            <a
              href="/my-designs"
              id="drillLogo"
              className="flex items-center align-middle my-auto space-x-3 rtl:space-x-reverse"
            >
              {/* <span class="self-center align-middle my-auto text-2xl font-semibold whitespace-nowrap dark:text-white">
                  DrillFlo
                </span> */}
              <img
                src="/logo-white.png"
                className="w-40 self-center align-middle my-auto text-2xl font-semibold whitespace-nowrap "
              />
              <span className="whitespace-nowrap font-bold rounded-full border-2 border-white px-2.5  text-sm text-white h-6 m-auto">
                BETA
              </span>
            </a>
            <button
              data-collapse-toggle="navbar-dropdown"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-dropdown"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div
              className="hidden w-full md:block md:w-auto align-middle my-auto"
              id="navbar-dropdown"
            >
              <ul className=" justify-center items-center flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 ">
                <li>
                  {isEditing ? (
                    // Input field for editing
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)} // Update the title on change
                      // onBlur={handleBlur} // Exit edit mode on blur
                      // onKeyDown={handleKeyDown} // Exit edit mode on Enter
                      className="block py-2 px-3 transition-all duration-200 bg-transparent text-white"
                      autoFocus
                    />
                  ) : (
                    // Display the title as text, turn into input when clicked
                    <div
                      className={`block py-2 px-4 m-2 text-white  rounded md:p-0 ${
                        collaboratorPermission !== "viewer" &&
                        `hover:border hover:border-white`
                      }`}
                      onClick={() => {
                        if (collaboratorPermission !== "viewer") {
                          setIsEditing(true); // Switch to edit mode when clicked
                        }
                      }}
                    >
                      {title}
                    </div>
                  )}
                </li>
                <li>
                  <ModeSelectionTool
                    mode={editMode}
                    handleModeChange={setEditMode}
                    modeSelectOpen={modeSelectOpen}
                    setModeSelectOpen={setModeSelectOpen}
                    collaboratorPermission={collaboratorPermission}
                  />
                </li>{" "}
                <div className="gap-1 flex flex-row">
                  <CollaboratorButton
                    documentId={slug}
                    title={title}
                    planVersion={planVersion}
                    currentCollaborators={data?.collaborators || []}
                  />

                  <button
                    className="inline-flex items-center px-2 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      print();
                    }}
                  >
                    {" "}
                    <Printer className="w-4 h-4 m-auto mr-2" />
                    Print
                  </button>

                  <div
                    style={{ marginTop: "0px" }}
                    className="flex flex-row gap-4 self-end right-0 relative"
                  >
                    <button
                      onClick={() => setIsShortcutsOpen(true)}
                      className="inline-flex items-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Command className="w-4 h-4 mr-2" />
                      Shortcuts
                    </button>
                  </div>
                </div>{" "}
                <div className=" text-gray-300 font-regular text-xs">
                  {savedStatus === true ? "Saved" : "Saving..."}
                </div>
                <li>
                  <button
                    onClick={undoChange}
                    className={`${
                      undoHistory.length > 0 ? `text-white` : `text-gray-400`
                    }`}
                  >
                    <Undo className="w-4 h-4 m-auto mr-2" />
                  </button>
                  <button
                    onClick={redoChange}
                    className={`${
                      redoHistory?.length > 0 ? `text-white` : `text-gray-400`
                    }`}
                  >
                    <Redo className="w-4 h-4 m-auto mr-2" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="flex flex-row pt-4 h-full">
          {editMode != "comment" && (
            <LeftNav
              setShowAudioTrackModal={setShowAudioTrackModal}
              setShowFieldSettingsModal={setShowFieldSettingsModal}
              showAudioTrackModal={showAudioTrackModal}
              showFeildSettingsModal={showFeildSettingsModal}
              showPerformerLabelModal={showPerformerLabelModal}
              setShowPerformerLabelModal={setShowPerformerLabelModal}
              showCoordinateSheetModal={showCoordinateSheetModal}
              setShowCoordinateSheetModal={setShowCoordinateSheetModal}
            />
          )}

          {/* <div
            style={{
              marginRight: "20px",
              border: "1px solid #ccc",
              padding: "10px",
              width: "300px",
            }}
          >
            <h2>Drill Edit Panel</h2>

           

           
          </div> */}
          {editMode === "PAINT_PERFORMERS" && (
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="performerSymbol">Performer Symbol</label>
              <input
                type="text"
                maxLength={1}
                id="performerSymbol"
                value={newPerformerSymbol}
                style={{ marginTop: "5px" }}
                onChange={(e) =>
                  setNewPerformerSymbol(e.target.value.toUpperCase())
                }
              />

              <label htmlFor="performerPaintStepSeperation">
                Step Seperation:{" "}
              </label>
              <input
                type="number"
                id="performerPaintStepSeperation"
                value={performerPaintStepSeperation}
                style={{ marginTop: "5px" }}
                min={1}
                max={50}
                onChange={(e) =>
                  setPerformerPaintStepSeperation(Number(e.target.value))
                }
              />
            </div>
          )}
          <div className="field-editor flex flex-col items-center m-auto justify-center content-center  mt-0 max-w-full overflow-hidden">
            {editMode === "drawShape" && (
              <div>
                <label>
                  Shape Type:
                  <select
                  // value={shapeType}
                  // onChange={(e) => setShapeType(e.target.value)}
                  >
                    <option value="freeform">Freeform</option>
                    <option value="curve">Curve</option>
                    <option value="straight">Straight</option>
                  </select>
                </label>
              </div>
            )}
            <canvas
              id="drillCanvas"
              width={canvasWidth}
              height={canvasHeight}
              className="rounded-md border"
              style={{
                // border: "1px solid #A3A3A3",
                marginTop: "px",
                borderRadius: "6px",
                padding: "2px",
                backgroundColor: "white",
                boxShadow:
                  "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
              }}
              ref={canvas}
            ></canvas>
            <div className="flex flex-row w-full gap-2 pb-20 ">
              <div className=" bg-gray-100 mt-2 text-gray-600 border text-center pl-4 rounded-sm font-semibold shadow-lg">
                <label>
                  Counts:
                  <input
                    type="number"
                    value={pages[drill?.selectedPageIndex ?? 0]?.counts || 8}
                    onChange={(e) =>
                      handleCountChangeForPage(
                        drill?.selectedPageIndex ?? 0, // Default to 0 if undefined
                        parseInt(e.target.value, 10) || 8
                      )
                    }
                    className="bg-transparent w-10 pl-2 "
                  />
                </label>
              </div>

              <div className=" bg-gray-100 mt-2 text-gray-600 border text-center px-2 rounded-sm font-semibold shadow-lg current-page-counts-control">
                <label>
                  Tempo (BPM):
                  <input
                    type="number"
                    value={pages[drill?.selectedPageIndex ?? 0]?.tempo || 120}
                    onChange={(e) =>
                      handleTempoChangeForPage(
                        drill?.selectedPageIndex ?? 0, // Default to 0 if undefined
                        parseInt(e.target.value, 10) || 120
                      )
                    }
                    min="1"
                    max="300"
                    className="bg-transparent w-14 pl-2"
                  />
                </label>
              </div>

              <div className=" bg-gray-100 mt-2 text-gray-600 border text-center px-2 rounded-sm font-semibold shadow-lg current-page-counts-control">
                <p>
                  Page: {drill?.selectedPageIndex + 1} /{" "}
                  {drill?.pages?.length || 0}
                </p>
              </div>
              <div className=" bg-gray-100 mt-2 text-gray-600 border text-center px-2 rounded-sm font-semibold shadow-lg current-page-counts-control">
                <p>Current Count: {Math.floor(currentCount + 1)}</p>
              </div>
              <div
                className={` transition-all ease-in-out bg-gray-400 text-white text-xs m-auto max-w-full text-center pl-4 pr-4 min-w-fit rounded-lg font-bold shadow-lg ${
                  selectedPerformerCoordinate.statement ? `block` : ` hidden`
                }`}
              >
                <p>
                  {selectedPerformerCoordinate &&
                    selectedPerformerCoordinate?.statement}
                </p>
              </div>
            </div>
            {drill?.engine._selectedPerformers.length > 1 && (
              <div className="relative bottom-32  z-40 left-1/2 -translate-x-1/2 bg-white rounded-lg shadow-lg p-4">
                <div className="flex items-center gap-4">
                  <Ruler className="w-5 h-5 text-gray-600" />
                  <div className="flex flex-col">
                    <label
                      htmlFor="spacing"
                      className="text-sm font-medium text-gray-700"
                    >
                      Adjust Spacing (steps)
                    </label>
                    <input
                      type="number"
                      id="spacing"
                      min={1}
                      max={20}
                      defaultValue={2}
                      onChange={(e) => applyEvenSpacing(Number(e.target.value))}
                      className="mt-1 w-24 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <span className="text-sm text-gray-500">
                    {drill?.engine._selectedPerformers.length} performers
                    selected
                  </span>
                </div>
              </div>
            )}
          </div>
          {editMode != "comment" && (
            <RightNav
              alignPerformersHorizontally={alignPerformersHorizontally}
              alignPerformersVertically={alignPerformersToCenter}
              alignPerformersToLeft={alignPerformersToLeft}
              alignPerformersToRight={alignPerformersToRight}
              alignPerformersToTop={alignPerformersToTop}
              alignPerformersToBottom={alignPerformersToBottom}
              stepInterval={null}
              setStepInterval={null}
              setSnapToGrid={setSnapToSteps}
              setPerformerInterval={null}
              rotation={null}
              handleRotationChange={null}
              snapToGrid={snapToSteps}
              showMovementArrows={showMovementArrows}
              setShowMovementArrows={setShowMovementArrows}
              rotateClockwise={handleRotateClockwise}
              rotateCounterClockwise={handleRotateCounterClockwise}
            />
          )}
          {editMode == "comment" && (
            <Comments
              designId={slug}
              pageNumber={drill?.selectedPageIndex ?? 0 + 1}
              currentUser={currentUser}
              commentCounts={commentCounts}
              collaborators={data?.collaborators}
              setCommentCounts={setCommentCounts}
            />
          )}

          <div className="border-t bg-white p-4 w-full flex flex-row max-w-full bottom-0 absolute h-24 ">
            <div className="flex items-center gap-4 w-full h-full">
              {isPlaying ? (
                <button
                  disabled={!isPlaying}
                  onClick={handleStop}
                  className="h-20 w-20 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center"
                >
                  <Pause className="h-6 w-6" />
                </button>
              ) : (
                <button
                  disabled={isPlaying}
                  onClick={handlePlay}
                  className="h-20 w-20 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center"
                >
                  <Play className="h-6 w-6" />
                </button>
              )}

              <div className="h-16 flex flex-row mt-0 px-4 w-[80%]  overflow-scroll gap-2  document-pages-row">
                {drill?.pages &&
                  drill?.pages.map((page, index) => {
                    const isSelected = currentPage === index;
                    const pageCounts = pages[index]?.counts || 1; // Avoid division by zero
                    const progressPercentage = Math.min(
                      (currentCount / pageCounts) * 100,
                      100
                    ).toFixed(2); // Cap at 100%

                    return (
                      <div
                        key={index}
                        className={` transition-all ease-in-out duration-150 relative bg-gray-100 rounded-lg h-full cursor-pointer hover:bg-gray-200 ${
                          isSelected && `border-2 border-blue-600`
                        }`}
                        onClick={() => {
                          handlePageChange(index);
                        }}
                        style={{
                          background: isSelected
                            ? `linear-gradient(to right, #9c9c9c ${progressPercentage}%, #f3f4f6 ${progressPercentage}%)`
                            : undefined,
                        }}
                      >
                        <div
                          className="text-sm text-gray-600 flex items-center gap-1 text-left pl-2"
                          style={{ width: `${pages[index]?.counts * 20}px` }}
                        >
                          {index}
                          <span className="text-xs text-gray-500">
                            {pages[index]?.counts} counts
                          </span>
                          {commentCounts[index] > 0 ? (
                            <div
                              className="flex flex-row mr-2 ml-auto cursor-pointer w-fit"
                              onClick={() => setEditMode("comment")}
                            >
                              <MessageCircleMore className="h-4 w-4 mr-2 text-gray-500" />
                              <span className="text-xs text-gray-700 ">
                                {commentCounts[index + 1]}
                              </span>
                            </div>
                          ) : (
                            <div className="flex flex-row mt-4 ml-auto " />
                          )}
                        </div>
                      </div>
                    );
                  })}

                {editMode !== "comment" && (
                  <div
                    onClick={() => {
                      handleAddPage();
                    }}
                    className="flex cursor-pointer justify-between gap-4 rounded-lg border border-gray-100 bg-gray-200 p-4 px-8 text-sm font-medium shadow-sm hover:border-gray-200 add-new-page-button"
                  >
                    +
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ShortcutsList
        isOpen={isShortcutsOpen}
        onClose={() => setIsShortcutsOpen(false)}
      />
    </>
  );
}

export default FieldEditor2;
