import { useCallback, useEffect, useRef, useState } from "react";
import "../AppDC.css";
import Engine, {
  DrillColors,
  EditMode,
  MovementSensitivity,
} from "@drillflo/drillcore/lib/2d/Engine";
import Drill, { HashType } from "@drillflo/drillcore/lib/Drill";
import ExportEngine from "@drillflo/drillcore/lib/2d/ExportEngine";

import { Rectangle } from "paper";
import Performer, { PerformerSymbol } from "@drillflo/drillcore/lib/Performer";

import React from "react";

import FieldSettingsModal from "./fieldSettingsModal";
import AudioSettingsModal from "./audioSettingsModal.tsx";
import ModeSelectionTool from "./modeSelectControl";
import { useDocumentAccess } from "../services/documentAccess.tsx";

import PerformerModal from "./performerModel";
import PDFExportButton from "./drillPDF";
import CollaboratorButton from "./collaboratorButton";
import LeftNav from "./leftNav";
import Comments from "./comments";
import RightNav from "./rightControlBar";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-hot-toast";
import { analytics, auth, db } from "../services/firebase";
import { useNavigate, useParams } from "react-router-dom";
import MarketingNavbar from "./marketingNav";
import LoadingSpinner from "./loadingSpinner";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Timeline from "./elements/Timeline.tsx";
import {
  Command,
  MessageCircleMore,
  Pause,
  Play,
  Redo,
  Ruler,
  Undo,
} from "lucide-react";
import Helmet from "react-helmet";
import Joyride from "react-joyride";
import { ShortcutsList } from "./keyboardShortcuts.tsx";
import convertToMarchingCoordinates from "../hooks/convertToMarchingCoordinate";
import PrintPreview from "./drillView/printPreview.tsx";
import { Printer, ChevronLeft, ChevronRight, X, Download } from "lucide-react";
import { analyzeSpacing } from "../services/spacingHelpers";
import CoordinateModal from "./elements/coordinateModal";
import { Color } from "paper/dist/paper-core";
import { logEvent } from "firebase/analytics";

function DemoViewer() {
  const slug = "TwYNZ3BkSDf5IdNY6rEF";

  const canvas = useRef<HTMLCanvasElement>(null);
  const exportCanvas = useRef<HTMLCanvasElement>(null);

  const [drill, setDrill] = useState<Drill | null>(null);

  const [engine, setEngine] = useState<Engine | null>(null);
  const [exportEngine, setExportEngine] = useState<ExportEngine | null>(null);

  const [zoomSpeed, setZoomSpeed] = useState<number>(22);
  const [endzones, setEndzones] = useState<boolean>(false);
  const [hashType, setHashType] = useState<HashType>(HashType.COLLEGE);
  const [snapToSteps, setSnapToSteps] = useState<boolean>(true);
  const [showMovementArrows, setShowMovementArrows] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<string>("PAN");
  const [redrawNonce, setRedrawNonce] = useState(0); // "Nonce" to trigger redraws
  const [newPerformerSymbol, setNewPerformerSymbol] = useState<string>("X");
  const [performerPaintStepSeperation, setPerformerPaintStepSeperation] =
    useState<number>(4);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [commentCounts, setCommentCounts] = useState({});

  const [title, setTitle] = useState<string>("Untitled");
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [audio, setAudio] = useState(null);
  const [tempo, setTempo] = useState<number>(120);
  const [currentUser, setCurrentUser] = useState(null);
  const [firebaseLoaded, setFirebaseLoaded] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [collaboratorPermission, setCollaboratorPermission] =
    useState("editor");
  const [savedStatus, setSavedStatus] = useState<boolean>(true);

  const [pagetransitionPercentage, setPageTransitionPercentage] =
    useState<number>(0);
  const [pages, setPages] = useState([]);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);

  const [data, setData] = useState(null);
  const [undoHistory, setUndoHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);

  const [tempoChanges, setTempoChanges] = useState({});

  const [currentCount, setCurrentCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Store current bounds of the screen, which keeps our x,y and width, height
  const [bounds, setBounds] = useState(new Rectangle(0, 0, 1000, 600));

  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth * 0.75);
  const [canvasHeight, setCanvasHeight] = useState(window.innerHeight * 0.65);
  const [showExportDialog, setShowExportDialog] = useState<boolean>(false);

  const animationRef = useRef<number>();
  const lastFrameTimeRef = useRef<number>(0);
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const navigate = useNavigate();

  const animate = useCallback(
    (currentTime: number) => {
      if (!isPlaying || !drill) return;
      // toast(`${currentTime}`);
      // Calculate time elapsed since last frame
      const deltaTime = lastFrameTimeRef.current
        ? (currentTime - lastFrameTimeRef.current) / 1000
        : 0;
      lastFrameTimeRef.current = currentTime;

      // Get current page info
      const currentPageIndex = drill.selectedPageIndex;
      const currentPageData = pages[currentPageIndex];
      const currentPageTempo = currentPageData?.tempo || 120;
      const currentPageCounts = currentPageData?.counts || 8;

      // Calculate how much to increment the count based on tempo
      // (tempo is beats per minute, so divide by 60 for beats per second)
      const countsPerSecond = currentPageTempo / 60;
      const countIncrement = deltaTime * countsPerSecond;

      // Update current count
      setCurrentCount((prevCount) => {
        const newCount = prevCount + countIncrement;

        // If we've reached the end of the current page's counts
        if (newCount >= currentPageCounts) {
          // Check if there's a next page
          if (currentPageIndex < drill.pages.length - 1) {
            // Move to next page
            drill.disableDraw = true;
            drill.selectPage(currentPageIndex + 1);
            setCurrentPage(currentPageIndex + 1);
            setPageTransitionPercentage(0);
            drill.disableDraw = false;
            // Return the remainder counts for the next page
            return newCount - currentPageCounts;
          } else {
            // No more pages, stop playing
            setIsPlaying(false);
            setCurrentCount(0);
            setPageTransitionPercentage(0);
            if (audioRef.current) {
              audioRef.current.pause();
              audioRef.current.currentTime = 0;
            }
            return 0;
          }
        }

        // Calculate transition percentage
        const newPercentage = (newCount / currentPageCounts) * 100;
        setPageTransitionPercentage(newPercentage);

        return newCount;
      });

      animationRef.current = requestAnimationFrame(animate);
    },
    [isPlaying, drill, pages]
  );
  useEffect(() => {
    if (isPlaying) {
      lastFrameTimeRef.current = performance.now();
      animationRef.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(animationRef.current);
      if (audioRef.current) {
        audioRef.current.pause();
      }
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, [isPlaying, animate]);
  useEffect(() => {
    if (audioUrl) {
      audioRef.current.src = audioUrl;
      audioRef.current.load();
    }
  }, [audioUrl]);

  const scaleCanvas = useCallback(() => {
    if (!canvas.current) return;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const newCanvasWidth = Math.round(screenWidth * 0.75);
    const newCanvasHeight = Math.round(screenHeight * 0.65);

    canvas.current.width = newCanvasWidth;
    canvas.current.height = newCanvasHeight;

    setCanvasWidth(newCanvasWidth);
    setCanvasHeight(newCanvasHeight);

    // Update bounds to match new canvas size while maintaining scale
    setBounds(new Rectangle(0, 0, newCanvasWidth, newCanvasHeight).scale(0.95));

    // Redraw if engine exists
    if (engine) {
      engine.drawToCanvas(bounds);
    }
  }, [engine, bounds]);

  // Adjust the canvas size on initial load and window resize
  useEffect(() => {
    setBounds(new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95));

    scaleCanvas(); // Initial scaling

    window.addEventListener("resize", scaleCanvas); // Listen for window resize
    return () => {
      window.removeEventListener("resize", scaleCanvas); // Clean up on component unmount
    };
  }, []);

  useEffect(() => {
    scaleCanvas();
    window.addEventListener("resize", scaleCanvas);
    return () => window.removeEventListener("resize", scaleCanvas);
  }, [engine]);

  useEffect(() => {
    if (!canvas.current) return;
    const DEFAULT_DRILL_COLORS: DrillColors = {
      background: new Color("#ffffff"),
      cells: new Color("#d3fefe"),
      halfYardLines: new Color("#73bcd4"),
      yardLines: new Color("#404040"),
      hashes: new Color("##404040"),
      performers: new Color("#040F16"),
      yardLineLetters: new Color(0, 0.4),
      selectedPerformer: new Color("#4842f5"),
      selectionRectangle: new Color("#4842f5"),
      previousPositionLine: new Color("#ff9e9e"),
      nextPositionLine: new Color("#7edb7d"),
      collision: new Color("#FF0000"),
      performerPaintPath: new Color("#000000"),
    };
    const userColors = DEFAULT_DRILL_COLORS; // Replace with logic to get user preferences

    const engine = new Engine(canvas.current, userColors, endzones);

    engine.hashType = hashType;
    engine.movementSensitivity = snapToSteps
      ? MovementSensitivity.FULL_STEP
      : MovementSensitivity.FREE;
    engine.movementArrows = false;
    engine.newPerformerSymbol = newPerformerSymbol as PerformerSymbol;
    engine.performerPaintStepSeperation = performerPaintStepSeperation;
    const drill = new Drill(engine);
    setEngine(engine);
    setDrill(drill);
    drill.selectPage(0);

    setInitialLoad(true);
    setBounds(new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95));

    // Add event listener for mouse scroll to zoom in and out
    const handleWheel = (event: WheelEvent) => {
      if (!canvas.current) return;

      event.preventDefault();
      // Get the amount of zoom from the wheel event and calculate the scale factor
      const zoom = event.deltaY;
      const scaleAmount = Math.exp(-zoom * zoomSpeed * 0.00001);

      // Get current mouse position relative to the canvas
      const canvasRect = canvas.current.getBoundingClientRect();
      const mouseX = event.clientX - canvasRect.left;
      const mouseY = event.clientY - canvasRect.top;

      // Adjust the bounds to keep the zoom centered at the mouse position
      setBounds((prevBounds) => {
        let newBounds = prevBounds.clone();

        // Calculate the mouse position as a percentage of the bounds
        const mousePercentX = (mouseX - newBounds.x) / newBounds.width;
        const mousePercentY = (mouseY - newBounds.y) / newBounds.height;

        // Scale the bounds
        newBounds = newBounds.scale(scaleAmount);

        // Calculate the new position of the top-left corner to keep the mouse point fixed
        newBounds.x = mouseX - mousePercentX * newBounds.width;
        newBounds.y = mouseY - mousePercentY * newBounds.height;

        return newBounds;
      });
    };

    // Add event listener for click and drag to pan
    let isDragging = false;
    let draggingType:
      | "pan"
      | "moveSelection"
      | "createSelection"
      | "paintPerformers"
      | null = null;
    let mouseMoved = false;
    let startX = 0;
    let startY = 0;
    let mouseDownX = 0;
    let mouseDownY = 0;

    const handleMouseDown = (event: MouseEvent) => {
      if (!canvas.current) return;
      isDragging = true;

      const canvasRect = canvas.current.getBoundingClientRect();

      startX = event.clientX - canvasRect.left;
      startY = event.clientY - canvasRect.top;
      mouseDownX = startX;
      mouseDownY = startY;

      const point = engine.isPointInSelectionRectangle(startX, startY);

      if (point) {
        draggingType = "moveSelection";
        engine.movementOriginPoint = point;
      } else {
        // If edit mode is man, or the middle mouse button is pressed, pan the view
        if (engine.editMode === EditMode.PAN || event.button === 1) {
          draggingType = "pan";
          event.preventDefault();
        } else if (engine.editMode === EditMode.SELECT_AND_MOVE) {
          draggingType = "createSelection";
          engine.startSelection(startX, startY);
        } else if (engine.editMode === EditMode.PAINT_PERFORMERS) {
          draggingType = "paintPerformers";
          engine.startPerformerPainting(startX, startY);
        }
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!canvas.current || !isDragging) return;

      const canvasRect = canvas.current.getBoundingClientRect();

      // Calculate the new mouse position relative to the canvas
      const currentX = event.clientX - canvasRect.left;
      const currentY = event.clientY - canvasRect.top;

      // Calculate how much the mouse moved since the last position
      const dx = currentX - startX;
      const dy = currentY - startY;

      const dxFromMouseDown = currentX - mouseDownX;
      const dyFromMouseDown = currentY - mouseDownY;

      // Check if drag
      if (
        !mouseMoved &&
        (Math.abs(dxFromMouseDown) > 0.25 || Math.abs(dyFromMouseDown) > 0.25)
      ) {
        mouseMoved = true;
      }

      if (mouseMoved) {
        if (draggingType === "moveSelection") {
          engine.moveSelectedPerformers(currentX, currentY);
          setRedrawNonce((prevNonce) => prevNonce + 1);
        } else if (draggingType === "createSelection") {
          engine.updateSelection(currentX, currentY);
          setRedrawNonce((prevNonce) => prevNonce + 1);
        } else if (draggingType === "pan") {
          // Update the bounds using the delta
          setBounds((prevBounds) => {
            const newBounds = prevBounds.clone();
            newBounds.x += dx;
            newBounds.y += dy;
            return newBounds;
          });
        } else if (draggingType === "paintPerformers") {
          engine.updatePerformerPainting(currentX, currentY);
          setRedrawNonce((prevNonce) => prevNonce + 1);
        }
      }

      // Update the starting position for the next move
      startX = currentX;
      startY = currentY;
    };

    const handleMouseUp = (event: MouseEvent) => {
      // If the mouse didn't move, consider it a click
      if (!mouseMoved && engine) {
        // Get the mouse position relative to the canvas
        const canvasRect = canvas.current?.getBoundingClientRect();
        const x = event.clientX - canvasRect!.left;
        const y = event.clientY - canvasRect!.top;

        // Ignore middle mouse button clicks
        if (event.button === 1) return;

        engine.handleClick(x, y);
        setRedrawNonce((prevNonce) => prevNonce + 1);
      }

      if (draggingType === "createSelection" && mouseMoved) {
        engine.endSelection();
        setRedrawNonce((prevNonce) => prevNonce + 1);
      }

      if (draggingType === "paintPerformers" && mouseMoved) {
        engine.endPerformerPainting(drill);

        setRedrawNonce((prevNonce) => prevNonce + 1);
      }

      isDragging = false;
      mouseMoved = false;
    };

    canvas.current.addEventListener("wheel", handleWheel);
    canvas.current.addEventListener("mousedown", handleMouseDown);
    canvas.current.addEventListener("mousemove", handleMouseMove);
    canvas.current.addEventListener("mouseup", handleMouseUp);
    setInitialLoad(true);
    setIsInitialized(true); // Mark initialization as complete
    // Cleanup event listeners on component unmount
    return () => {
      canvas.current?.removeEventListener("wheel", handleWheel);
      canvas.current?.removeEventListener("mousedown", handleMouseDown);
      canvas.current?.removeEventListener("mousemove", handleMouseMove);
      canvas.current?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [canvas.current, zoomSpeed]);

  // Whenever the bounds or nonce changes, we can update the engine's bounds
  useEffect(() => {
    if (engine && canvas.current) {
      engine.drawToCanvas(bounds);
    }
  }, [bounds, redrawNonce]);

  const updatePages = (newPages) => {
    setPages(newPages); // Update state
  };

  useEffect(() => {
    const loadData = async () => {
      if (!isInitialized || !engine || !initialLoad) return;
      // setLoading(true);
      try {
        const docRef = doc(db, "designs", slug);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const designData = docSnap.data();
          const drillUnpack = await designData.serializedDrill;

          if (drillUnpack) {
            const parsedData = JSON.parse(drillUnpack);
            if (parsedData.pages.length > 1) {
              const firstPage = parsedData.pages[0];

              const isFirstPageEmpty =
                firstPage && firstPage.performerPositions.length === 0;

              if (isFirstPageEmpty && parsedData.pages.length >= 1) {
                parsedData.pages.shift();
                console.log("Removing empty page");
              }
            }

            if (!parsedData.displayOptions) {
              setFirebaseLoaded(false);
              return;
            }
            const drill = Drill.load(parsedData, engine);
            drill.draw();
            engine.drawToCanvas(bounds);

            setBounds(
              new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95)
            );

            setDrill(drill);
            setTitle(designData.title || "Untitled");
            drill.title = designData.title;
            setEndzones(parsedData.displayOptions.endZones);
            setShowMovementArrows(parsedData.displayOptions.movementArrows);
            setHashType(parsedData.displayOptions.hashType);
            setUndoHistory(designData.history);
            setRedoHistory(designData.redoHistory);
            setSnapToSteps(
              designData.snapToGrid !== undefined ? designData.snapToGrid : true
            );
            setData(designData);
            setCurrentPage(0);
            drill.selectPage(0);

            setAudioUrl(designData.audioFile || "");
            // setTempo(designData.pages[drill?.selectedPageIndex].tempo ?? 120);
            setPages(designData.pages);
            setFirebaseLoaded(true);
            drill.selectPage(0);
          } else if (!drillUnpack) {
            // const drill = new Drill(engine);
            // setDrill(drill);
            // drill.selectPage(1);
            // drill.draw();
            // setBounds(
            //   new Rectangle(0, 0, canvasWidth, canvasHeight).scale(0.95)
            // );
            setFirebaseLoaded(true);
          }
        }
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [isInitialized, engine, initialLoad, slug]);

  // Whenever the endzones state changes, we can update the drill's endzones
  useEffect(() => {
    if (engine) {
      engine.endZones = endzones;
      engine.hashType = hashType;
      engine.movementSensitivity = snapToSteps
        ? MovementSensitivity.FULL_STEP
        : MovementSensitivity.FREE;
      engine.movementArrows = showMovementArrows;
      engine.newPerformerSymbol = newPerformerSymbol as PerformerSymbol;
      engine.performerPaintStepSeperation = performerPaintStepSeperation;
      drill?.draw();
      engine.drawToCanvas(bounds);
    }
  }, [
    endzones,
    hashType,
    snapToSteps,
    showMovementArrows,
    newPerformerSymbol,
    performerPaintStepSeperation,
  ]);

  // Whenever the edit mode changes, we can update the engine's edit mode
  useEffect(() => {
    if (engine) {
      engine.editMode = EditMode[editMode as keyof typeof EditMode];
    }
  }, [editMode, engine]);

  useEffect(() => {
    // Debounce the update to avoid too many writes
    setSavedStatus(false);
    // if (firebaseLoaded == false || initialLoad === false) return fetchData;

    const debounceTimer = setTimeout(() => {
      setSavedStatus(true);
    }, 2000); // Wait for 2 seconds of inactivity before updating
    return () => clearTimeout(debounceTimer);
  }, [title, tempo, drill, engine, redrawNonce, pages]);

  useEffect(() => {
    if (engine) {
      engine.pageTransitionPercentage = pagetransitionPercentage;
      setRedrawNonce((prevNonce) => prevNonce + 1);
    }
  }, [pagetransitionPercentage, engine]);

  useEffect(() => {
    const fetchCommentCounts = async () => {
      const commentsRef = collection(db, "comments");
      const q = query(commentsRef, where("designId", "==", slug));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const counts = {};
        snapshot.docs.forEach((doc) => {
          const comment = doc.data();
          counts[comment.pageNumber] = (counts[comment.pageNumber] || 0) + 1;
        });
        setCommentCounts(counts);
      });

      return () => unsubscribe();
    };

    fetchCommentCounts();
  }, [slug]);
  // Animation and Playing

  const handlePageChange = (index: number) => {
    console.log("Changing tempo");
    if (drill === null) return console.error("Can't change tempo");
    drill.selectPage(index);
    setRedrawNonce((prevNonce) => prevNonce + 1);
    setCurrentCount(0);
    setCurrentPage(index);
    if (tempoChanges[index]) {
      setTempo(tempoChanges[index]);
    }
  };
  const handleCountChangeForPage = (pageIndex: number, newCounts: number) => {
    const counts = newCounts || 0;
    updatePages((prevPages) => {
      const newPages = [...prevPages];
      newPages[pageIndex] = { ...newPages[pageIndex], counts };
      return newPages;
    });
  };

  const handleTempoChangeForPage = (pageIndex: number, newTempo: number) => {
    try {
      setTempoChanges((prev) => ({
        ...prev,
        [pageIndex]: newTempo,
      }));
      if (drill?.selectedPageIndex === pageIndex) {
        setTempo(newTempo);
      }

      // Update pages array
      setPages((prevPages) =>
        prevPages.map((page, idx) =>
          idx === pageIndex ? { ...page, tempo: newTempo } : page
        )
      );
    } catch (error) {
      console.error("Error changing tempo", error);
    }
  };
  const handlePlay = () => {
    if (!drill) return toast.error("Something went wrong");
    setIsPlaying(true);
    setCurrentCount(0);
    lastFrameTimeRef.current = 0;

    // Calculate time offset for audio based on current position in the show
    let totalTimeBeforeCurrentPage = 0;
    for (let i = 0; i < drill.selectedPageIndex; i++) {
      const pageTempo = pages[i].tempo || 120;
      const pageTime = ((pages[i].counts - 1) / pageTempo) * 60;
      totalTimeBeforeCurrentPage += pageTime;
    }

    if (audioRef.current && audioUrl) {
      audioRef.current.currentTime = totalTimeBeforeCurrentPage;
      audioRef.current.play();
    }

    animationRef.current = requestAnimationFrame(animate);
    logEvent(analytics, "play_demo_viewer", {
      viewed_at: Timestamp.now().toDate().toISOString(), // Log as ISO string for consistency
      page_viewed: "/",
      device_type: navigator.userAgent.includes("Mobi") ? "mobile" : "desktop",
    });
  };

  const handleStop = () => {
    setIsPlaying(false);
    setCurrentCount(0);
    setPageTransitionPercentage(0);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    logEvent(analytics, "stop_demo_viewer", {
      stopped_at: Timestamp.now().toDate().toISOString(), // Log as ISO string for consistency
      page_viewed: "/",
      device_type: navigator.userAgent.includes("Mobi") ? "mobile" : "desktop",
    });
  };

  useEffect(() => {
    if (!isPlaying && animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isPlaying]);
  useEffect(() => {
    // Check local storage for the tutorial flag
    const tutorialSeen = localStorage.getItem("tutorial1Seen");
    if (!tutorialSeen) {
      setShowTutorial(true); // Show tutorial if flag not found
    }
  }, []);

  const handleCloseTutorial = () => {
    // Set flag in local storage so it doesn't show again
    localStorage.setItem("tutorial1Seen", "true");
    setShowTutorial(false); // Hide the tutorial
  };

  // const coordsBack = convertToMarchingCoordinates(-28, 8);
  // setSelectedPerformerCoordinate(coordsBack);

  // Performer Adjustment and Manipulation tools
  function applyEvenSpacing(desiredSpacing) {
    if (
      !drill ||
      !drill.engine._selectedPerformers ||
      drill.engine._selectedPerformers.length === 0
    )
      return;

    // Get the current page's performer positions
    const currentPage = drill.pages[drill._selectedPageIndex];
    if (!currentPage || !currentPage.performerPositions) return;

    const pagePerformers = currentPage.performerPositions;
    const selectedPerformers = drill.engine._selectedPerformers;

    if (selectedPerformers.length < 2) return;

    // Get current positions of selected performers
    const positions = selectedPerformers
      .map((index) => pagePerformers.get(index))
      .filter((pos): pos is NonNullable<typeof pos> => pos !== undefined);

    // Analyze current arrangement
    const analysis = analyzeSpacing(positions);

    if (analysis.direction === "horizontal") {
      applyHorizontalSpacing(
        selectedPerformers,
        pagePerformers,
        desiredSpacing
      );
    } else {
      applyVerticalSpacing(selectedPerformers, pagePerformers, desiredSpacing);
    }

    drill.draw();
    // setRedrawNonce();
  }

  function applyHorizontalSpacing(
    selectedPerformers: number[],
    pagePerformers: Map<number, any>,
    spacing: number
  ): void {
    // Sort performers by current horizontal position
    const sortedPerformers = [...selectedPerformers].sort((a, b) => {
      const posA = pagePerformers.get(a)?.horizontalStepsFrom50 ?? 0;
      const posB = pagePerformers.get(b)?.horizontalStepsFrom50 ?? 0;
      return posA - posB;
    });

    // Get leftmost position as anchor
    const startPos =
      pagePerformers.get(sortedPerformers[0])?.horizontalStepsFrom50 ?? 0;

    // Apply new spacing
    sortedPerformers.forEach((performerIndex, i) => {
      const position = pagePerformers.get(performerIndex);
      if (position) {
        pagePerformers.set(performerIndex, {
          ...position,
          horizontalStepsFrom50: startPos + i * spacing,
        });
      }
    });
  }

  if (loading)
    return (
      <>
        <LoadingSpinner />
      </>
    );

  return (
    <>
      <div className=" h-full bg-white max-h-full w-full">
        <div className="flex flex-col pt-4 h-full">
          <div className="field-editor flex flex-col items-center m-auto justify-center content-center  mt-0 max-w-full overflow-hidden">
            <canvas
              id="drillCanvas"
              width={canvasWidth}
              height={canvasHeight}
              style={{
                // border: "1px solid #A3A3A3",
                marginTop: "px",
                borderRadius: "4px",
                padding: "2px",
                backgroundColor: "white",
                boxShadow:
                  "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
              }}
              ref={canvas}
            ></canvas>
            <div className="flex flex-row w-full gap-2 pb-20 ">
              <div className=" bg-gray-100 mt-2 text-gray-600 border text-center pl-4 rounded-sm font-semibold shadow-lg">
                <label>
                  Counts:
                  <input
                    type="number"
                    value={pages[drill?.selectedPageIndex ?? 0]?.counts || 8}
                    onChange={(e) =>
                      handleCountChangeForPage(
                        drill?.selectedPageIndex ?? 0, // Default to 0 if undefined
                        parseInt(e.target.value, 10) || 8
                      )
                    }
                    className="bg-transparent w-10 pl-2 "
                  />
                </label>
              </div>

              <div className=" bg-gray-100 mt-2 text-gray-600 border text-center px-2 rounded-sm font-semibold shadow-lg current-page-counts-control">
                <label>
                  Tempo (BPM):
                  <input
                    type="number"
                    value={pages[drill?.selectedPageIndex ?? 0]?.tempo || 120}
                    onChange={(e) =>
                      handleTempoChangeForPage(
                        drill?.selectedPageIndex ?? 0, // Default to 0 if undefined
                        parseInt(e.target.value, 10) || 120
                      )
                    }
                    min="1"
                    max="300"
                    className="bg-transparent w-14 pl-2"
                  />
                </label>
              </div>

              <div className=" bg-gray-100 mt-2 text-gray-600 border text-center px-2 rounded-sm font-semibold shadow-lg current-page-counts-control">
                <p>
                  Page: {drill?.selectedPageIndex + 1} /{" "}
                  {drill?.pages?.length || 0}
                </p>
              </div>
              <div className=" bg-gray-100 mt-2 text-gray-600 border text-center px-2 rounded-sm font-semibold shadow-lg current-page-counts-control">
                <p>Current Count: {Math.floor(currentCount + 1)}</p>
              </div>
              <span className="bg-blue-100 m-auto text-slate-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full ">
                DrillFlo Beta Demo
              </span>
            </div>
          </div>

          <div className="border-t bg-white p-4 w-full flex flex-row max-w-full   h-24 ">
            <div className="flex items-center gap-4 w-full h-full">
              {isPlaying ? (
                <button
                  disabled={!isPlaying}
                  onClick={handleStop}
                  className="h-20 w-20 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center"
                >
                  <Pause className="h-6 w-6" />
                </button>
              ) : (
                <button
                  disabled={isPlaying}
                  onClick={handlePlay}
                  className="h-20 w-20 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center"
                >
                  <Play className="h-6 w-6" />
                </button>
              )}

              <div className="h-16 flex flex-row mt-0 px-4 w-[80%]  overflow-scroll gap-2  document-pages-row">
                {drill?.pages &&
                  drill?.pages.map((page, index) => {
                    const isSelected = currentPage === index;
                    const pageCounts = pages[index]?.counts || 1; // Avoid division by zero
                    const progressPercentage = Math.min(
                      (currentCount / pageCounts) * 100,
                      100
                    ).toFixed(2); // Cap at 100%

                    return (
                      <div
                        key={index}
                        className={` transition-all ease-in-out duration-150 relative bg-gray-100 rounded-lg h-full cursor-pointer hover:bg-gray-200 ${
                          isSelected && `border-2 border-blue-600`
                        }`}
                        onClick={() => {
                          handlePageChange(index);
                        }}
                        style={{
                          background: isSelected
                            ? `linear-gradient(to right, #9c9c9c ${progressPercentage}%, #f3f4f6 ${progressPercentage}%)`
                            : undefined,
                        }}
                      >
                        <div
                          className="text-sm text-gray-600 flex items-center gap-1 text-left pl-2"
                          style={{ width: `${pages[index]?.counts * 20}px` }}
                        >
                          {index}
                          <span className="text-xs text-gray-500">
                            {pages[index]?.counts} counts
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoViewer;
