import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { use } from "marked";

export const createCheckoutSession = async (
  priceId: string,
  userId: string
) => {
  try {
    // Create the checkout session document
    const checkoutSessionRef = collection(
      db,
      "users",
      userId,
      "checkout_sessions"
    );

    const sessionData = {
      mode: "subscription",
      success_url: window.location.origin + "/",
      cancel_url: window.location.origin + "/pricing",
      // price: priceId, // Use the passed planId for the price
      line_items: [
        {
          price: priceId, // Use the passed planId for the price
          quantity: 1,
        },
      ],
      subscription_data: {
        trial_period_days: 30,
      },
      collect_shipping_address: false,

      metadata: {
        userId: userId,
      },
    };

    // Create the checkout session
    const docRef = await addDoc(checkoutSessionRef, sessionData);

    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data() as {
        error?: { message: string };
        url?: string;
      };
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        console.log(error);
        alert(`An error occurred: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const createPortalSession = async () => {
  try {
    const functions = getFunctions();
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    const { data } = await functionRef({
      returnUrl: window.location.origin + "/pricing",
    });

    // Redirect to the customer portal
    window.location.assign(data.url);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
