import React, { useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import MarketingNavbar from "../marketingNav";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../services/firebase";
import { useNavigate } from "react-router-dom";

interface OnboardingLayoutProps {
  children: React.ReactNode;
  currentStep: number;
  totalSteps: number;
  onNext: () => void;
  onBack: () => void;
  canProgress?: boolean;
}

export function OnboardingLayout({
  children,
  currentStep,
  totalSteps,
  onNext,
  onBack,
  canProgress = true,
}: OnboardingLayoutProps) {
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
      } else {
        navigate("/login"); // Or redirect to login
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]); // Include `navigate` in dependencies

  const exitOnboarding = () => {
    navigate("/");
  };

  return (
    <>
      <MarketingNavbar />
      <div className="min-h-screen bg-gradient-to-br from-blue-500 to-violet-600 flex items-center justify-center p-6">
        <div className="bg-white rounded-2xl shadow-xl w-full max-w-3xl min-h-[600px] relative overflow-hidden">
          {/* Progress bar */}
          <div className="absolute top-0 left-0 right-0 h-1 bg-gray-100">
            <div
              className="h-full bg-blue-500 transition-all duration-500 ease-out"
              style={{ width: `${(currentStep / totalSteps) * 100}%` }}
            />
          </div>

          <div className="p-8 flex flex-col h-full">
            {/* Content */}
            <div className="flex-1">{children}</div>

            {/* Navigation */}
            <div className="flex justify-between items-center mt-8 pt-6 border-t border-gray-100">
              <button
                onClick={onBack}
                className={`flex items-center px-4 py-2 rounded-lg transition-colors
                ${
                  currentStep === 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
                disabled={currentStep === 1}
              >
                <ChevronLeft className="w-5 h-5 mr-2" />
                Back
              </button>

              <div className="text-sm text-gray-500">
                Step {currentStep} of {totalSteps}
              </div>

              <button
                onClick={currentStep === totalSteps ? exitOnboarding : onNext}
                disabled={!canProgress}
                className={`flex items-center px-6 py-2 rounded-lg transition-all
                ${
                  canProgress
                    ? "bg-blue-500 text-white hover:bg-blue-600"
                    : "bg-gray-200 text-gray-500 cursor-not-allowed"
                }`}
              >
                {currentStep === totalSteps ? "Get Started" : "Next"}
                <ChevronRight className="w-5 h-5 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
