import React, { useState, useEffect } from "react";
import { Grid, Users, Layout } from "lucide-react";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../services/firebase";
import { onAuthStateChanged } from "firebase/auth";

export function PreferencesStep({
  onValidChange,
}: {
  onValidChange: (isValid: boolean) => void;
}) {
  const [fieldType, setFieldType] = useState("");
  const [gridSize, setGridSize] = useState("");
  const [performerCount, setPerformerCount] = useState("");
  const [loading, setLoading] = useState(false); // Store user data
  const [userData, setUserData] = useState(null); // Store user data

  const isValid = performerCount;
  const fetchUserData = async () => {
    setLoading(true);
    try {
      // Query the "users" collection to find the document with the matching uid
      const q = query(
        collection(db, "users"),
        where("uid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If we found a matching document, set the user data
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per uid
        const userData = { id: userDoc.id, ...userDoc.data() };
        setUserData(userData);
      } else {
        console.error("No user data found for the current UID");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData();
      } else {
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]); // Include `navigate` in dependencies

  useEffect(() => {
    const updateUserDoc = async () => {
      if (userData?.id && isValid) {
        const userRef = doc(db, "users", userData.id);

        try {
          await updateDoc(userRef, {
            onboarded: "Y",
            typicalPerformerCount: performerCount,
          });
        } catch (error) {
          console.error("Error updating document: ", error);
        }
      }
    };

    updateUserDoc();
  }, [isValid, userData?.id, performerCount]);

  React.useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  return (
    <div className="max-w-md mx-auto">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">
        What size ensemble?
      </h2>

      <div className="space-y-8">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-3">
            Typical Performer Count
          </label>
          <div className="grid grid-cols-2 gap-4">
            {["20-50", "50-100", "100-200", "200+"].map((count) => (
              <button
                key={count}
                onClick={() => setPerformerCount(count)}
                className={`p-3 border rounded-lg flex items-center justify-center transition-colors
                  ${
                    performerCount === count
                      ? "border-blue-500 bg-blue-50 text-blue-700"
                      : "border-gray-300 hover:bg-gray-50"
                  }`}
              >
                <Users className="w-4 h-4 mr-2" />
                {count}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
