import MarketingNavbar from "../components/marketingNav";
import React, { useState } from "react";
import { Book, Send, MessageSquareMore, ArrowRight } from "lucide-react";

export default function SupportPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus("sending");

    const webhookUrl =
      "https://discord.com/api/webhooks/1315517242229653596/YA1Sm1NdoUHfSe1aLCxzpUGPiRr3brTnj3QQUDZIdRTgl97MOhwq-gfzzh3ABu0Fgpo4"; // Replace with actual webhook URL

    const discordMessage = {
      embeds: [
        {
          title: `New Support Request: ${formData.subject}`,
          description: formData.message,
          fields: [
            { name: "Name", value: formData.name, inline: true },
            { name: "Email", value: formData.email, inline: true },
          ],
          color: 5814783,
        },
      ],
    };

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(discordMessage),
      });

      if (response.ok) {
        setStatus("success");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        setStatus("error");
      }
    } catch (error) {
      setStatus("error");
    }
  };
  return (
    <>
      <div className="h-screen text-left">
        <MarketingNavbar />

        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900 flex items-center gap-2">
              <MessageSquareMore className="h-8 w-8 text-indigo-600" />
              Support Center
            </h1>
          </div>
        </header>

        <main className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Left Column - Contact Form */}
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center gap-2">
                <Send className="h-6 w-6 text-indigo-600" />
                Contact Support
              </h2>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    value={formData.subject}
                    onChange={(e) =>
                      setFormData({ ...formData, subject: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    value={formData.message}
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                </div>

                <button
                  type="submit"
                  disabled={status === "sending"}
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  {status === "sending" ? "Sending..." : "Send Message"}
                </button>

                {status === "success" && (
                  <p className="text-green-600 text-center">
                    Message sent successfully!
                  </p>
                )}
                {status === "error" && (
                  <p className="text-red-600 text-center">
                    Failed to send message. Please try again.
                  </p>
                )}
              </form>
            </div>

            {/* Right Column - Resources */}
            <div className="space-y-8">
              {/* Guidebook Section */}
              <div className="bg-white rounded-xl shadow-lg p-8">
                <h2 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center gap-2">
                  <Book className="h-6 w-6 text-indigo-600" />
                  Documentation
                </h2>

                <div className="prose prose-indigo">
                  <p className="text-gray-600 mb-4">
                    Get started with our comprehensive guidebook. Find detailed
                    instructions, tutorials, and best practices for using our
                    platform effectively.
                  </p>

                  <a
                    href="/guidebook"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    View Guidebook
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </a>
                </div>
              </div>

              {/* FAQ Preview */}
              <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-xl shadow-lg p-8 text-white">
                <h2 className="text-2xl font-semibold mb-4">
                  Frequently Asked Questions
                </h2>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <ArrowRight className="h-6 w-6 mr-2 flex-shrink-0" />
                    <span>How do I reset my password?</span>
                  </li>
                  <li className="flex items-start">
                    <ArrowRight className="h-6 w-6 mr-2 flex-shrink-0" />
                    <span>What payment methods do you accept?</span>
                  </li>
                  <li className="flex items-start">
                    <ArrowRight className="h-6 w-6 mr-2 flex-shrink-0" />
                    <span>How can I upgrade my subscription?</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>{" "}
    </>
  );
}
