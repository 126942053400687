// Stripe configuration and constants
export const STRIPE_CONFIG = {
  prices: {
    professional: {
      monthly: "price_1QYumYELrUWyxuVLsqKyshEP", // Replace with your actual price ID
      annual: "price_1QYungELrUWyxuVLMenR7GnS", // Replace with your actual price ID
    },
  },
  // Replace with your actual URLs from Stripe Dashboard
  checkoutUrl: "https://checkout.stripe.com/c/pay/YOUR_CHECKOUT_URL",
  customerPortalUrl:
    "https://billing.stripe.com/p/login/test_28o14d0vq6Dr5zicMM",
};
