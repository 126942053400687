import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import MarketingNavbar from "../components/marketingNav";
import LoadingSpinner from "../components/loadingSpinner";
import SubscriptionButton from "../components/elements/subscriptionButton.tsx";
import { analytics, auth, getUserPlanData } from "../services/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { STRIPE_CONFIG } from "../config/stripe";
import ManageSubscriptionButton from "../components/elements/manageSubscription";
import { logEvent } from "firebase/analytics";
import { Timestamp } from "firebase/firestore";

export default function PricingPage() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [viewPricing, setViewPricing] = useState("monthly");
  const [planData, setPlanData] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserData(user);
        const planDataBack = await getUserPlanData(user.uid);
        setPlanData(planDataBack);
        console.log(planDataBack);
        logEvent(analytics, "view_pricing_page", {
          viewed_at: Timestamp.now().toDate().toISOString(), // Log as ISO string for consistency
          uid: user.uid,
          page_viewed: "/pricing",
          device_type: navigator.userAgent.includes("Mobi")
            ? "mobile"
            : "desktop",
          current_plan: planDataBack,
        });
      } else {
        alert("No user found, please log in");
        navigate("/login");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return (
      <>
        <MarketingNavbar />
        <LoadingSpinner />
      </>
    );
  }

  return (
    <>
      <MarketingNavbar />
      {planData && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
            <div className="flex">
              <div className="flex-1">
                <h3 className="text-sm font-medium text-blue-800">
                  Current Subscription
                </h3>
                <p className="mt-2 text-sm text-blue-700">
                  {planData?.planKey !== null && "Professional Tier"}
                </p>
              </div>
              <div className="ml-4">
                <ManageSubscriptionButton />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="sm:flex sm:flex-col sm:align-center p-10">
        {/* Pricing toggle */}
        <div className="relative self-center bg-slate-200 rounded-lg p-0.5 flex">
          <button
            type="button"
            onClick={() => setViewPricing("monthly")}
            className={`relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8 ${
              viewPricing === "monthly"
                ? "bg-slate-50 border-slate-50 text-slate-900 shadow-sm"
                : "border-transparent text-slate-900"
            }`}
          >
            Monthly billing
          </button>
          <button
            type="button"
            onClick={() => setViewPricing("annual")}
            className={`ml-0.5 relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8 ${
              viewPricing === "annual"
                ? "bg-slate-50 border-slate-50 text-slate-900 shadow-sm"
                : "border-transparent text-slate-900"
            }`}
          >
            Yearly billing
          </button>
        </div>

        {/* Pricing cards */}
        <div className="mt-12 space-y-3 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 md:max-w-5xl md:mx-auto xl:grid-cols-2">
          {/* Professional Plan */}
          <div className="rounded-3xl p-px bg-gradient-to-b from-blue-400 to-purple-500">
            <div className="bg-gray-50 p-2 rounded-3xl">
              <div className="p-6">
                <h2 className="text-xl leading-6 font-bold text-slate-900">
                  Professional
                </h2>
                <p className="mt-2 text-base text-slate-700 leading-tight">
                  For designers who are looking for efficiency and scalability.
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-bold text-slate-900 tracking-tighter">
                    {viewPricing === "monthly" ? "$25" : "$250"}
                  </span>
                  <span className="text-base font-medium text-slate-500">
                    {viewPricing === "monthly" ? "/mo" : "/year"}
                  </span>
                </p>
                <SubscriptionButton
                  priceId={STRIPE_CONFIG.prices.professional[viewPricing]}
                  userId={userData?.uid}
                  isLoading={loading}
                  planName="Professional"
                />
              </div>
              <div class="pt-6 pb-8 px-6">
                <h3 class="text-sm font-bold text-slate-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul role="list" class="mt-4 space-y-3">
                  <li class="flex space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-shrink-0 h-5 w-5 text-green-400"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      aria-hidden="true"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span class="text-base text-slate-700">
                      All Standard features
                    </span>
                  </li>
                  <li class="flex space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-shrink-0 h-5 w-5 text-green-400"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      aria-hidden="true"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span class="text-base text-slate-700">
                      Unlimited Drill Design Files
                    </span>
                  </li>
                  <li class="flex space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-shrink-0 h-5 w-5 text-green-400"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      aria-hidden="true"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span class="text-base text-slate-700">
                      Unlimited Performers
                    </span>
                  </li>
                  <li class="flex space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-shrink-0 h-5 w-5 text-green-400"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      aria-hidden="true"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span class="text-base text-slate-700">
                      Full Collaboration features to shared projects
                    </span>
                  </li>
                  <li class="flex space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-shrink-0 h-5 w-5 text-green-400"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      aria-hidden="true"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span class="text-base text-slate-700">
                      Customizable field layout
                    </span>
                  </li>
                  <li class="flex space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-shrink-0 h-5 w-5 text-green-400"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      aria-hidden="true"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span class="text-base text-slate-700">
                      High Resolution PDF Exports without watermark
                    </span>
                  </li>
                  <li class="flex space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-shrink-0 h-5 w-5 text-green-400"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      aria-hidden="true"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span class="text-base text-slate-700">
                      Future Exciting DrillFlo Features
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Starter Plan */}
          <div className="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200">
            <div className="p-8">
              <h2 className="text-xl leading-6 font-bold text-slate-900">
                Starter
              </h2>
              <p className="mt-2 text-base text-slate-700 leading-tight">
                For new designers who want to try out designing
              </p>
              <p className="mt-8">
                <span className="text-4xl font-bold text-slate-900 tracking-tighter">
                  $0
                </span>
                <span className="text-base font-medium text-slate-500">
                  {viewPricing === "monthly" ? "/mo" : "/year"}
                </span>
              </p>
              <a
                href="/register"
                className="mt-8 block w-full bg-slate-900 rounded-md py-2 text-sm font-semibold text-white text-center"
              >
                Join as a Starter
              </a>
            </div>
            <div class="pt-6 pb-8 px-6">
              <h3 class="text-sm font-bold text-slate-900 tracking-wide uppercase">
                What's included
              </h3>
              <ul role="list" class="mt-4 space-y-3">
                <li class="flex space-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 h-5 w-5 text-green-400"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                  <span class="text-base text-slate-700">
                    5 Drill Design Files
                  </span>
                </li>
                <li class="flex space-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 h-5 w-5 text-green-400"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                  <span class="text-base text-slate-700">
                    Can View and Collaborate on other users designs
                  </span>
                </li>
                <li class="flex space-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 h-5 w-5 text-green-400"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                  <span class="text-base text-slate-700">
                    Access to High School Field Layout
                  </span>
                </li>
                <li class="flex space-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 h-5 w-5 text-green-400"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                  <span class="text-base text-slate-700">
                    Limited Performer Count (Maximum 50 performers)
                  </span>
                </li>
                <li class="flex space-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 h-5 w-5 text-green-400"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                  <span class="text-base text-slate-700">
                    Save/Export to PDF with watermark
                  </span>
                </li>
                <li class="flex space-x-3">
                  <X className="w-4 text-red-600" />
                  <span class="text-base text-slate-700">
                    No collaboration feature on documents created by you
                  </span>
                </li>
                <li class="flex space-x-3">
                  <X className="w-4 text-red-600" />
                  <span class="text-base text-slate-700">
                    Limited access to future features
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
