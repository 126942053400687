import React, { useState } from 'react';
import { MousePointer, Grid, Users, Music, FileSpreadsheet } from 'lucide-react';

export function AppTourStep({ onValidChange }: { onValidChange: (isValid: boolean) => void }) {
  const [currentFeature, setCurrentFeature] = useState(0);

  const features = [
    {
      icon: Grid,
      title: 'Interactive Grid',
      description: 'Our smart grid system helps you place performers with precision. Snap to grid, adjust spacing, and visualize formations easily.'
    },
    {
      icon: Users,
      title: 'Performer Management',
      description: 'Organize your performers by section, assign positions, and track movements throughout your show.'
    },
    {
      icon: Music,
      title: 'Audio Integration',
      description: 'Upload your music and synchronize your drill designs with the audio timeline for perfect timing.'
    },
    {
      icon: FileSpreadsheet,
      title: 'Dot Sheets',
      description: 'Generate detailed coordinate sheets for each performer automatically. Export in various formats.'
    }
  ];

  React.useEffect(() => {
    onValidChange(true);
  }, [onValidChange]);

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-900 mb-2">Key Features</h2>
      <p className="text-gray-600 mb-8">Let's take a quick tour of DrillFlo's main features</p>

      <div className="relative">
        {/* Feature Navigation */}
        <div className="flex space-x-2 mb-6">
          {features.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentFeature(index)}
              className={`w-3 h-3 rounded-full transition-colors
                ${index === currentFeature ? 'bg-blue-500' : 'bg-gray-300'}`}
            />
          ))}
        </div>

        {/* Feature Content */}
        <div className="bg-gray-50 rounded-2xl p-8 relative overflow-hidden">
          <div className="flex items-start space-x-6">
            <div className="w-16 h-16 bg-blue-100 rounded-xl flex items-center justify-center flex-shrink-0">
              {React.createElement(features[currentFeature].icon, {
                className: "w-8 h-8 text-blue-500"
              })}
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">
                {features[currentFeature].title}
              </h3>
              <p className="text-gray-600 leading-relaxed">
                {features[currentFeature].description}
              </p>
            </div>
          </div>

          {/* Navigation Arrows */}
          <div className="absolute bottom-8 right-8 flex space-x-2">
            <button
              onClick={() => setCurrentFeature(prev => Math.max(0, prev - 1))}
              className={`p-2 rounded-lg transition-colors
                ${currentFeature === 0 
                  ? 'text-gray-300 cursor-not-allowed' 
                  : 'text-gray-600 hover:bg-gray-200'}`}
              disabled={currentFeature === 0}
            >
              ←
            </button>
            <button
              onClick={() => setCurrentFeature(prev => Math.min(features.length - 1, prev + 1))}
              className={`p-2 rounded-lg transition-colors
                ${currentFeature === features.length - 1
                  ? 'text-gray-300 cursor-not-allowed' 
                  : 'text-gray-600 hover:bg-gray-200'}`}
              disabled={currentFeature === features.length - 1}
            >
              →
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}