import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  analytics,
  auth,
  registerWithEmailAndPassword,
  // signInWithGoogle,
} from "../services/firebase";
import { db } from "../services/firebase";
import {
  query,
  collection,
  getDocs,
  where,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import MarketingNavbar from "../components/marketingNav";
import { toast } from "react-hot-toast";
import WaitlistForm from "../components/waitlist";
import { logEvent } from "firebase/analytics";
import Footer from "../components/footer";

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [codeExists, setCodeExists] = useState(false);
  const [codesFound, setCodesFound] = useState(false);

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const [hasAccessCode, setHasAccessCode] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();
  const location = useLocation();

  const register = () => {
    if (!firstName) return toast.error("Please Enter Your Name");
    if (!email) return toast.error("Please Enter Your Email");
    if (!password) return toast.error("Please Enter a Password");
    if (termsAndConditions !== true)
      return toast.error(
        "Please accept the Terms and Conditions",
        termsAndConditions
      );
    setIsNewUser(true);
    registerWithEmailAndPassword(firstName, lastName, email, password);
  };

  const handleAccessCode = async (event) => {
    event.preventDefault();
    const docRef = doc(db, "invitationCodes", accessCode);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const codeData = docSnap.data();
      setCodeExists(true);
      checkCodeValid();
      logEvent(analytics, "valid_invitation_code_entered", {
        timestamp: Timestamp.now().toDate().toISOString(),
        page_viewed: "/register",
        device_type: navigator.userAgent.includes("Mobi")
          ? "mobile"
          : "desktop",
      });
    } else {
      toast.error("Invalid Invitation Code");
      logEvent(analytics, "incorrect_invitation_code_entered", {
        timestamp: Timestamp.now().toDate().toISOString(),
        page_viewed: "/register",
        device_type: navigator.userAgent.includes("Mobi")
          ? "mobile"
          : "desktop",
      });
    }
  };

  const checkCodeValid = async () => {
    try {
      const q = query(
        collection(db, "invitationCodes"),
        where("__name__", "==", accessCode)
      );
      const docFromDB = await getDocs(q);
      const data = docFromDB.docs[0].data();

      const docRef = doc(db, "invitationCodes", accessCode);

      if (!data.codeUsed) {
        setHasAccessCode(true);
        toast.success("Invitation Code is Valid");
        await updateDoc(docRef, { codeUsed: true });
      } else {
        setHasAccessCode(false);
        toast.error("Invitation Code Has Been Used");
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) {
      // Optionally show a loading screen
      return;
    }

    if (user) {
      if (isNewUser) {
        navigate("/welcome-to-drillflo");
      } else {
        navigate("/my-designs");
      }
    }
  }, [user, loading, location.state]);

  return (
    <div className="">
      <MarketingNavbar />
      <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
          <div>
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Create your Account
            </h2>
            <p class="mt-2 text-center text-sm text-gray-600">
              already have an account?
              <Link
                to="/login"
                class="font-medium text-purple-600 hover:text-purple-500"
              >
                {" "}
                log in{" "}
              </Link>
            </p>
          </div>
          {hasAccessCode ? (
            <div class="mt-8 space-y-6">
              <input type="hidden" name="remember" value="true" />
              <div class="rounded-md shadow-sm -space-y-px">
                <div>
                  <label for="first-name" class="sr-only">
                    First Name
                  </label>
                  <input
                    id="first-name"
                    name="name"
                    type="name"
                    autocomplete="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="First Name"
                  />
                </div>
                <div>
                  <label for="last-name" class="sr-only">
                    Last name
                  </label>
                  <input
                    id="last-name"
                    name="lastname"
                    type="lastName"
                    autocomplete="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Last Name"
                  />
                </div>
                <div>
                  <label for="email-address" class="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autocomplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <label for="password" class="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    required="true"
                    value={termsAndConditions}
                    onChange={(e) => setTermsAndConditions(!termsAndConditions)}
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="terms" class="font-light text-gray-500 ">
                    I accept the{" "}
                    <a
                      class="font-medium text-primary-600 hover:underline "
                      href="/terms-and-conditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-center"></div>
              </div>

              <div>
                <button
                  type="submit"
                  onClick={register}
                  class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg
                      class="h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Create My Account
                </button>{" "}
                {/* <button onClick={signInWithGoogle} class="blank-button pb-8">
                <img src={GoogleLogo} alt="Sign in with Google" />
              </button>{" "} */}
              </div>
            </div>
          ) : (
            <div>
              <h3 className="text-blue-600 font-bold text-2xl">
                Beta Test Registration
              </h3>
              <p className="pt-4 ">
                DrillFlo is currently in a Beta Test. You can create an account
                with an Invitation from an existing user.
              </p>
              <div>
                <form onSubmit={handleAccessCode}>
                  <label for="access-code" class="sr-only">
                    Enter Invitation Code
                  </label>
                  <input
                    id="access-code"
                    name="access-code"
                    type="name"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                    required
                    class="appearance-none rounded-none relative mt-4 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Invitation Code"
                  />{" "}
                  <button
                    type="submit"
                    class="group relative w-full flex justify-center py-2 mt-4 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                      {/* <KeyIcon className="h-5 w-5 color: inherit	" /> */}
                    </span>
                    Check Code
                  </button>{" "}
                </form>
              </div>
              <p className="pt-8 font-bold">Don't have an Access Code?</p>
              <WaitlistForm />
            </div>
          )}
        </div>
      </div>{" "}
      <Footer />
    </div>
  );
}
export default RegisterPage;
