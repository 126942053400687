import { logEvent } from "firebase/analytics";
import { Timestamp } from "firebase/firestore";
import { useEffect } from "react";
import MarketingNavbar from "../components/marketingNav";
import { analytics } from "../services/firebase";

export default function GuideBookPage() {
  useEffect(() => {
    logEvent(analytics, "view_guidebook", {
      timestamp: Timestamp.now().toDate().toISOString(), // Log as ISO string for consistency
      page_viewed: "/guidebook",
      device_type: navigator.userAgent.includes("Mobi") ? "mobile" : "desktop",
    });
  }, []);

  return (
    <>
      <div className="h-screen">
        <MarketingNavbar />

        <iframe
          class="clickup-embed clickup-dynamic-height"
          src="https://doc.clickup.com/9011662620/d/h/8cj63rw-1871/3313333aca870ef"
          onwheel=""
          width="100%"
          height="100%"
          style={{
            background: "transparent",
            border: "1px solid #ccc",
          }}
        />
        <script
          async
          src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
        ></script>
      </div>
    </>
  );
}
