import logo from "./logo.svg";
import "./App.css";
import FieldEditor from "./components/fieldEditor";
import { Route, Routes } from "react-router-dom";
import Login from "./views/login";
import MyDesigns from "./views/my-design";
import RegisterPage from "./views/register";
import HomePage from "./views/home";
import MyAccount from "./views/my-account";
import PricingPage from "./views/pricing";
import toast, { Toaster } from "react-hot-toast";
import FieldEditor2 from "./components/fieldEditor2.tsx";
import AccessDenied from "./components/AccessDenied.tsx";
import ChangeLogPage from "./views/changeLog";
import GuideBookPage from "./views/guidebook";
import InvitationPage from "./views/invitationPage";
import { SupportWidget } from "./components/supportWidget/supportWidget.tsx";
import AboutPage from "./views/about";
import MobileDisabled from "./views/mobileDisabled";
import PasswordReset from "./views/resetPassword";
import PasswordResetConfirmation from "./views/passwordResetPage";
import OnboardingAccountSetup from "./views/account-setup";
import PrivacyPolicy from "./views/privacy-policy";
import TermsAndConditions from "./views/terms-and-conditions";
import DrillFloFreeLandingPage from "./views/SEO-Pages/drillflo-free";
import DrillFloOnlineFreeLandingPage from "./views/SEO-Pages/drillflo-online-free";
import SupportPage from "./views/support";
import BlogHome from "./views/blog/blog";
import SingleBlog from "./views/blog/singleBlog";

function App() {
  return (
    <div className=" ">
      <Toaster />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/marching-band-drill-design-software"
          element={<HomePage />}
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/why-is-drillflo-the-best-free-drill-design-software"
          element={<DrillFloFreeLandingPage />}
        />
        <Route
          path="/free-online-drill-writing-software"
          element={<DrillFloOnlineFreeLandingPage />}
        />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/mobile-disabled" element={<MobileDisabled />} />
        <Route
          path="/access-denied"
          element={<AccessDenied message="You don't have Access" />}
        />
        <Route path="/my-designs" element={<MyDesigns />} />
        <Route
          path="/welcome-to-drillflo"
          element={<OnboardingAccountSetup />}
        />

        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/changelog" element={<ChangeLogPage />} />
        <Route path="/guidebook" element={<GuideBookPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route
          path="/reset-password-confirmation"
          element={<PasswordResetConfirmation />}
        />

        <Route path="/designs/:slug" element={<FieldEditor2 />} />
        <Route path="/invitation" element={<InvitationPage />} />
        {/* <Route path="/designs2/:slug" element={<FieldEditor2 />} /> */}
        {/* <Route path="/about" element={<About />} /> */}

        <Route path="/blog" element={<BlogHome />} />
        <Route path="/blog/:slug" element={<SingleBlog />} />
      </Routes>

      <SupportWidget />
    </div>
  );
}

export default App;
