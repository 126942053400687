import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import MarketingNavbar from "../components/marketingNav";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("A password reset link has been sent to your email.");
      setError("");
    } catch (err) {
      setError(err.message);
      setMessage("");
    }
  };

  return (
    <>
      <MarketingNavbar />
      <div style={{ maxWidth: "400px", margin: "0 auto", textAlign: "center" }}>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Password Reset
        </h2>
        <form onSubmit={handlePasswordReset}>
          <div style={{ marginBottom: "1rem" }}>
            <label
              htmlFor="email"
              style={{ display: "block", marginBottom: "0.5rem" }}
            >
              Enter your email:
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@domain.com"
              required
              style={{
                padding: "0.5rem",
                width: "100%",
                boxSizing: "border-box",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>
          <button
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            Send Reset Link
          </button>
        </form>
        {message && (
          <p style={{ marginTop: "1rem", color: "green" }}>{message}</p>
        )}
        {error && <p style={{ marginTop: "1rem", color: "red" }}>{error}</p>}
      </div>{" "}
    </>
  );
};

export default PasswordReset;
