import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../services/firebase";
import Footer from "../../components/footer";
import MarketingNavbar from "../../components/marketingNav";
import { Helmet } from "react-helmet";

export default function SingleBlog() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogCollection = collection(db, "blogs");
        const blogQuery = query(blogCollection, where("path", "==", slug));
        const querySnapshot = await getDocs(blogQuery);

        if (!querySnapshot.empty) {
          const blogData = querySnapshot.docs[0].data();
          setBlog(blogData);
        } else {
          setError("Blog not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [slug]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!blog) {
    return <p>Blog not found.</p>;
  }

  return (
    <>
      <Helmet>
        <title>{blog.title}</title>
        <meta
          name="description"
          content={blog.excerpt || blog.body.slice(0, 150)}
        />
        <meta name="keywords" content={blog.keywords || "blog, article"} />
      </Helmet>
      <MarketingNavbar />
      <main className="container mx-auto px-5 md:px-0 w-full md:w-10/12 lg:w-5/12 font-work text-left">
        <section>
          <div className="py-5">
            <div className="w-fit text-white px-2.5 py-1 bg-primary text-xs md:text-sm rounded-md mb-2 md:mb-4 font-medium">
              {blog.category}
            </div>
            <h3 className="text-base-content font-semibold text-xl md:text-2xl lg:text-4xl leading-5 md:leading-10">
              {blog.title}
            </h3>
            <div className="mt-3 md:mt-6 flex items-center gap-5 text-base-content/60">
              <div className="flex items-center gap-3">
                <a
                  // href={`/author/${blog.authorId}`}
                  className="text-xs md:text-sm font-medium hover:text-primary transition hover:duration-300"
                >
                  {blog.author}
                </a>
              </div>
              <p className="text-xs md:text-sm">
                <time dateTime={blog.date_added.toDate()}>
                  {blog.date_added.toDate().toDateString()}
                </time>
              </p>
            </div>
          </div>
          <div className="mt-8">
            <img
              width="800"
              height="462"
              alt={blog.imageAlt}
              className="rounded-xl"
              src={blog.image}
            />
          </div>
        </section>

        <section>
          <div className="font-serif">
            <div className="mt-8">
              <div
                className="text-xl leading-8 text-base-content/80"
                dangerouslySetInnerHTML={{ __html: blog.body }}
              ></div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
