import React, { useState } from "react";
import { createPortalSession } from "../../services/stripe.tsx";

export default function ManageSubscriptionButton() {
  const [isLoading, setIsLoading] = useState(false);

  const handlePortalSession = async () => {
    setIsLoading(true);
    try {
      await createPortalSession();
    } catch (error) {
      console.error("Portal session error:", error);
      alert("Unable to access the billing portal. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={handlePortalSession}
      disabled={isLoading}
      className="bg-blue-100 hover:bg-blue-200 text-blue-800 px-4 py-2 rounded-md text-sm font-medium"
    >
      {isLoading ? "Loading..." : "Manage Subscription"}
    </button>
  );
}
