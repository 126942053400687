import React from 'react';
import { Wand2, Users, Layout } from 'lucide-react';

export function WelcomeStep() {
  return (
    <div className="text-center">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">
        Welcome to DrillFlo
      </h1>
      <p className="text-gray-600 mb-12 max-w-lg mx-auto">
        Create stunning drill designs with ease. Our intuitive tools help you bring your vision to life, whether you're designing for marching bands, color guards, or drum corps.
      </p>

      <div className="grid grid-cols-3 gap-8 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mx-auto mb-4">
            <Wand2 className="w-6 h-6 text-blue-500" />
          </div>
          <h3 className="font-medium text-gray-900 mb-2">Intuitive Design</h3>
          <p className="text-sm text-gray-500">Create formations with our easy-to-use grid system</p>
        </div>

        <div className="text-center">
          <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mx-auto mb-4">
            <Users className="w-6 h-6 text-blue-500" />
          </div>
          <h3 className="font-medium text-gray-900 mb-2">Performer Management</h3>
          <p className="text-sm text-gray-500">Organize and track your performers efficiently</p>
        </div>

        <div className="text-center">
          <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mx-auto mb-4">
            <Layout className="w-6 h-6 text-blue-500" />
          </div>
          <h3 className="font-medium text-gray-900 mb-2">Smart Templates</h3>
          <p className="text-sm text-gray-500">Start quickly with pre-built formations</p>
        </div>
      </div>
    </div>
  );
}