import { Box, Grid, Hash, Settings2 } from "lucide-react";
import React from "react";

const FieldSettingsModal = ({
  fieldColor,
  setFieldColor,
  hashMarkType,
  setHashMarkType,
  onClose,
  performerLabelLocation,
  setPerformerLabelLocation,
  setShowEndzone,
  showEndzone,
}) => (
  <div
    className="relative z-50"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>

    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="bg-white px-6 py-5 sm:p-6">
            <div className="flex flex-col gap-4">
              <h3 className="flex items-center gap-2 text-xl">
                <Settings2 className="h-5 w-5" />
                Field Settings
              </h3>

              <div className="grid gap-2">
                <div className="flex items-center gap-2">
                  <Hash className="h-4 w-4 text-muted-foreground" />
                  <h3 className="text-sm font-medium">Hask Mark Type</h3>
                </div>

                <div className="flex flex-col">
                  <select
                    value={hashMarkType}
                    onChange={(e) => setHashMarkType(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="HIGH_SCHOOL">High School</option>
                    <option value="COLLEGE">College</option>
                    <option value="PROFESSIONAL">Professional</option>
                  </select>
                </div>
              </div>

              {/* Show Endzone */}
              <div className="grid gap-2">
                <div className="flex items-center gap-2">
                  <Grid className="h-4 w-4 text-muted-foreground" />
                  <h3 className="text-sm font-medium">Show Endzone</h3>
                </div>
                <input
                  type="checkbox"
                  checked={showEndzone}
                  onChange={(e) => setShowEndzone(e.target.checked)}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
                <label className="text-sm font-medium text-gray-700">
                  {/* Endzone */}
                </label>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="bg-gray-50 px-4 py-3 flex justify-end gap-2">
            <button
              onClick={onClose}
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FieldSettingsModal;
