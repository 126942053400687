import React from "react";
import { createCheckoutSession } from "../../services/stripe.tsx";

interface SubscriptionButtonProps {
  priceId: string;
  userId: string;
  isLoading: boolean;
  planName: string;
}

export default function SubscriptionButton({
  priceId,
  userId,
  isLoading,
  planName,
}: SubscriptionButtonProps) {
  const handleSubscribe = async () => {
    console.log("Handling sub", priceId, userId);
    try {
      await createCheckoutSession(priceId, userId);
    } catch (error) {
      console.error("Subscription error:", error);
      alert("Failed to start subscription process. Please try again.");
    }
    console.log("Done sub");
  };

  return (
    <button
      onClick={handleSubscribe}
      disabled={isLoading}
      className={`mt-8 block w-full ${
        planName === "Professional"
          ? "bg-blue-600 hover:bg-purple-600"
          : "bg-slate-900"
      } transition-all ease-linear rounded-md py-2 text-sm font-semibold text-white text-center`}
    >
      {isLoading ? (
        <span className="flex items-center justify-center">
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Processing...
        </span>
      ) : (
        `Join as a ${planName}`
      )}
    </button>
  );
}
