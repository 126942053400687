import React, { useEffect, useState } from "react";
import {
  getAuth,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import MarketingNavbar from "../components/marketingNav";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";
import { toast } from "react-hot-toast";

export default function PasswordResetConfirmation() {
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode"); // Firebase sends this as a query param
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) navigate("/my-designs");
  }, [user, loading]);

  useEffect(() => {
    if (oobCode === null) navigate("/login");
  }, [oobCode]);

  const HandlePasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    const auth = getAuth();

    try {
      // Verify the reset code
      await verifyPasswordResetCode(auth, oobCode);

      // Confirm the password reset
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage(
        "Your password has been reset successfully. You can now log in with your new password."
      );
      navigate("/login");
    } catch (err) {
      toast.error(err.message);
    } finally {
    }
  };

  return (
    <>
      <MarketingNavbar />
      <div style={{ maxWidth: "400px", margin: "0 auto", textAlign: "center" }}>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Password Reset
        </h2>
        {message ? (
          <p style={{ color: "green" }}>{message}</p>
        ) : (
          <form onSubmit={HandlePasswordReset}>
            <div style={{ marginBottom: "1rem" }}>
              <label
                htmlFor="newPassword"
                style={{ display: "block", marginBottom: "0.5rem" }}
              >
                New Password:
              </label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter your new password"
                required
                style={{
                  padding: "0.5rem",
                  width: "100%",
                  boxSizing: "border-box",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label
                htmlFor="confirmPassword"
                style={{ display: "block", marginBottom: "0.5rem" }}
              >
                Confirm Password:
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your new password"
                required
                style={{
                  padding: "0.5rem",
                  width: "100%",
                  boxSizing: "border-box",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              {loading ? "Resetting..." : "Reset Password"}
            </button>
          </form>
        )}
        {error && <p style={{ marginTop: "1rem", color: "red" }}>{error}</p>}
      </div>
    </>
  );
}
