import React, { useState, useCallback } from "react";
import { OnboardingLayout } from "../components/OnboardingFlow/OnboardingLayout.tsx";
import { WelcomeStep } from "../components/OnboardingFlow/WelcomeStep.tsx";
import { AccountSetupStep } from "../components/OnboardingFlow/AccountSetupStep.tsx";
import { PreferencesStep } from "../components/OnboardingFlow/PreferencesStep.tsx";
import { AppTourStep } from "../components/OnboardingFlow/AppTourStep.tsx";
import { TemplateStep } from "../components/OnboardingFlow/TemplateStep.tsx";
import { FirstSetStep } from "../components/OnboardingFlow/FirstSetStep.tsx";
import { CompletionStep } from "../components/OnboardingFlow/CompletionStep.tsx";

function OnboardingAccountSetup() {
  const [currentStep, setCurrentStep] = useState(1);
  const [canProgress, setCanProgress] = useState(true);

  const totalSteps = 5;

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleValidChange = useCallback((isValid: boolean) => {
    setCanProgress(isValid);
  }, []);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <WelcomeStep />;
      case 2:
        return <AccountSetupStep onValidChange={handleValidChange} />;
      case 3:
        return <PreferencesStep onValidChange={handleValidChange} />;
      case 4:
        return <AppTourStep onValidChange={handleValidChange} />;
      // case 5:
      //   return <TemplateStep onValidChange={handleValidChange} />;
      // case 5:
      //   return <FirstSetStep onValidChange={handleValidChange} />;
      case 5:
        return <CompletionStep />;
      default:
        return null;
    }
  };

  return (
    <OnboardingLayout
      currentStep={currentStep}
      totalSteps={totalSteps}
      onNext={handleNext}
      onBack={handleBack}
      canProgress={canProgress}
    >
      {renderStep()}
    </OnboardingLayout>
  );
}

export default OnboardingAccountSetup;
