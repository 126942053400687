import React, { useState, useEffect } from "react";
import { CheckCircle, Book, Users, Video } from "lucide-react";
import Confetti from "react-confetti";

export function CompletionStep() {
  const [showConfetti, setShowConfetti] = useState(true);

  // Automatically stop confetti after a few seconds
  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="max-w-2xl mx-auto text-center relative">
      <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
        <CheckCircle className="w-10 h-10 text-green-500" />
      </div>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">You're all set!</h2>
      <p className="text-gray-600 mb-12 max-w-md mx-auto">
        Your DrillFlo account is ready to go. Here are some resources to help
        you get started:
      </p>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          confettiSource={{ x: 300, y: 100, w: 100, h: 100 }}
          numberOfPieces={100}
          tweenDuration={1000}
          recycle={false}
        />
      )}
      <div className="grid grid-cols-3 gap-6 mb-8">
        <a
          href="#tutorials"
          className="p-6 border border-gray-200 rounded-xl hover:border-blue-500 transition-colors group"
        >
          <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mx-auto mb-4">
            <Video className="w-6 h-6 text-blue-500" />
          </div>
          <h3 className="font-semibold text-gray-900 mb-2 group-hover:text-blue-500">
            Video Tutorials
          </h3>
          <p className="text-sm text-gray-600">
            Learn the basics through our video guides
          </p>
        </a>

        <a
          href="/guidebook"
          className="p-6 border border-gray-200 rounded-xl hover:border-blue-500 transition-colors group"
        >
          <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mx-auto mb-4">
            <Book className="w-6 h-6 text-blue-500" />
          </div>
          <h3 className="font-semibold text-gray-900 mb-2 group-hover:text-blue-500">
            Documentation
          </h3>
          <p className="text-sm text-gray-600">Detailed guides and tips</p>
        </a>

        <a
          href="#community"
          className="p-6 border border-gray-200 rounded-xl hover:border-blue-500 transition-colors group"
        >
          <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mx-auto mb-4">
            <Users className="w-6 h-6 text-blue-500" />
          </div>
          <h3 className="font-semibold text-gray-900 mb-2 group-hover:text-blue-500">
            Community
          </h3>
          <p className="text-sm text-gray-600">
            Join our community of designers
          </p>
        </a>
      </div>

      <div className="bg-blue-50 rounded-xl p-6 text-left">
        <h4 className="font-semibold text-gray-900 mb-2">Quick Tip</h4>
        <p className="text-sm text-gray-600">
          Press{" "}
          <kbd className="px-2 py-1 bg-white rounded border border-gray-300 text-xs">
            Shift + ?
          </kbd>{" "}
          anywhere in the app to see keyboard shortcuts
        </p>
      </div>
    </div>
  );
}
