import React from "react";
import { Crown, X, Check, HardDrive } from "lucide-react";
import SubscriptionButton from "./subscriptionButton.tsx";
import { STRIPE_CONFIG } from "../../config/stripe";

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentUsage: number;
  storageLimit: number;
  userData: string;
  reason: string;
}

export default function UpgradeModal({
  isOpen,
  onClose,
  currentUsage,
  storageLimit,
  userData,
  reason,
}: UpgradeModalProps) {
  if (!isOpen) return null;

  const usagePercentage = (currentUsage / storageLimit) * 100;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-lg mx-4 relative overflow-hidden">
        <div className="absolute top-4 right-4">
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="bg-gradient-to-br from-blue-600 to-purple-600 p-6 text-white">
          <div className="flex items-center gap-3 mb-4">
            <Crown className="h-8 w-8" />
            <h2 className="text-2xl font-bold">Upgrade Your Plan</h2>
          </div>
          <p className="text-white/90">
            {!reason &&
              "You've reached your storage limit. Upgrade to continue creating amazing drill designs!"}
            {reason === "collab" &&
              "Upgrade to collaborate and share your drill design document with other users"}
          </p>
        </div>

        <div className="p-6">
          {(!reason || reason === "storage") && (
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2 text-gray-600">
                  <HardDrive className="h-5 w-5" />
                  <span>Storage Usage</span>
                </div>
                <span className="text-sm font-medium">
                  {usagePercentage.toFixed(1)}%
                </span>
              </div>
              <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                <div
                  className="h-full bg-blue-600 rounded-full"
                  style={{ width: `${usagePercentage}%` }}
                />
              </div>
              <p className="text-sm text-gray-500 mt-1">
                {currentUsage} of {storageLimit} drills used
              </p>
            </div>
          )}

          <div className="space-y-4">
            <div className="border rounded-lg p-4 hover:border-blue-500 cursor-pointer transition-colors">
              <div className="flex items-center justify-between mb-2">
                <h3 className="font-semibold text-lg">Professional Plan</h3>
                <span className="text-blue-600 font-bold">$25.00/mo</span>
              </div>
              <ul className="space-y-2">
                <li className="flex items-center gap-2 text-gray-600">
                  <Check className="h-4 w-4 text-green-500" />
                  <span>Unlimited Storage</span>
                </li>
                <li className="flex items-center gap-2 text-gray-600">
                  <Check className="h-4 w-4 text-green-500" />
                  <span>Unlimited Performers</span>
                </li>
                <li className="flex items-center gap-2 text-gray-600">
                  <Check className="h-4 w-4 text-green-500" />
                  <span>Collaboration</span>
                </li>
              </ul>
            </div>
          </div>
          <SubscriptionButton
            priceId={STRIPE_CONFIG.prices.professional["monthly"]}
            userId={userData}
            // isLoading={loading}
            planName="Professional"
          />

          <p className="text-center text-sm text-gray-500 mt-4">
            Questions? Contact our support team
          </p>
        </div>
      </div>
    </div>
  );
}
