import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { db } from "../services/firebase"; // Assuming you have this configured already
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import {
  ArrowPathIcon,
  ClockIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  GlobeAmericasIcon,
  LockClosedIcon,
  UserGroupIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/outline";
import { Clock } from "three";
import WaitlistForm from "../components/waitlist";
import ChangeLog from "../components/changelog.tsx";
import Footer from "../components/footer";
import DemoViewer from "../components/demoViewer.tsx";

function MarketingApp() {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is DrillFlo?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "DrillFlo is a modern, cloud-based marching band drill design software designed to make drill writing accessible and collaborative. With intuitive tools, real-time commenting, and a user-friendly interface, DrillFlo empowers band directors and drill writers to create custom drill charts quickly and efficiently.",
        },
      },
      {
        "@type": "Question",
        name: "Is DrillFlo free to use?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "DrillFlo offers a free tier that lets you explore core features of the software. It's perfect for those just starting with marching band drill design software and looking for an affordable, flexible alternative.",
        },
      },
      {
        "@type": "Question",
        name: "Who is DrillFlo for?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "DrillFlo is designed for high school and college band directors, as well as professional drill writers. Whether you're new to drill design or looking for a more affordable and user-friendly alternative to expensive software like Pyware, DrillFlo has the tools you need.",
        },
      },
      {
        "@type": "Question",
        name: "How is DrillFlo different from other marching band design software?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Unlike traditional marching band design software, DrillFlo is fully cloud-based, affordable, and focused on collaboration. Unique features include real-time commenting on stills and a modern, minimalistic interface that makes drill writing easier than ever.",
        },
      },
      {
        "@type": "Question",
        name: "Can I collaborate with others using DrillFlo?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes! Collaboration is at the heart of DrillFlo. Multiple users can comment and work together in real time, streamlining the design process and fostering teamwork among drill writers and directors.",
        },
      },
      {
        "@type": "Question",
        name: "Does DrillFlo support other marching arts like indoor guard or drumline?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "While DrillFlo is currently focused on marching band drill design, future updates aim to support indoor guard, drumline, and other marching arts. Stay tuned as we continue to expand our features!",
        },
      },
      {
        "@type": "Question",
        name: "What makes DrillFlo the best choice for marching band drill design software?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "DrillFlo stands out as an affordable, intuitive, and efficient solution for creating marching band drill designs. Its cloud-based platform eliminates installation hassles, while its focus on collaboration and ease of use ensures every drill writer can work faster and smarter.",
        },
      },
      {
        "@type": "Question",
        name: "Are there paid plans for DrillFlo?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes! DrillFlo offers a professional tier to suit your needs, from free basic tools to premium features for professionals. Check out our pricing page for more details.",
        },
      },
      {
        "@type": "Question",
        name: "What’s included in the free tier of DrillFlo?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The free tier gives you access to essential drill design features, making it perfect for hobbyists and those exploring the software.",
        },
      },
      {
        "@type": "Question",
        name: "Is DrillFlo good for beginners?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes! DrillFlo is designed to be intuitive and easy to use, making it perfect for those just starting with marching band drill design.",
        },
      },
      {
        "@type": "Question",
        name: "Why choose DrillFlo over other drill design software?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "DrillFlo stands out with its focus on accessibility, collaboration, and affordability, designed for today’s drill writers and directors.",
        },
      },
    ],
  };

  const [animationClass, setAnimationClass] = useState("animate-fadeIn");
  const words = ["Design", "Collaboration", "Sharing", "Flo"];
  const [currentWord, setCurrentWord] = useState("Design");

  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      // First, fade out the current word
      setAnimationClass("animate-fadeOut");

      // After fadeOut completes (500ms), change the word and fade in
      setTimeout(() => {
        setCurrentWord((prevWord) => {
          const currentIndex = words.indexOf(prevWord);
          const nextIndex = (currentIndex + 1) % words.length;
          return words[nextIndex];
        });

        // Apply fade-in after word change
        setAnimationClass("animate-fadeIn");
      }, 500); // Wait for fadeOut to complete before updating the word
    }, 3000); // Change every 3 seconds (with time for fade-out + fade-in)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, "waitlistEmails"), {
        email,
        timestamp: serverTimestamp(),
      });
      setSuccessMessage("Thank you for joining the waitlist!");
      setEmail("");
    } catch (error) {
      console.error("Error adding email to waitlist: ", error);
      alert("There was an issue adding you to the waitlist. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const features = [
    {
      name: "Customizable Field View",
      description:
        "  High school, college, or NFL fields—DrillFlo adapts to your needs.",
      icon: ViewfinderCircleIcon,
    },
    {
      name: "Easy Collaboration",
      description:
        "Collaborate with Marching Band or Drum Corps design teams to design a better production. Share, Comment, Collaborate with your colleagues",
      icon: GlobeAmericasIcon,
    },
    {
      name: "Real-Time Feedback",
      description:
        " See changes in real-time as you tweak formations, with instant feedback for seamless adjustments.",
      icon: ClockIcon,
    },
    {
      name: "Easy Performer Placement",
      description:
        "   Drag and drop performers exactly where you need them, from simple lines to complex arcs and spirals.",
      icon: UserGroupIcon,
    },
  ];
  const softwareSchema = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: "DrillFlo",
    description:
      "DrillFlo is a cloud-based Marching Band Drill Design Software, created to allow ensembles and designers to build their next production. Designed with Marching Bands, Drum Corps, Colorguards, and Percussion Ensembles in mind, this software brings a new approach to creating incredible moments.",
    applicationCategory: "EducationalApplication",
    operatingSystem: "Web",
    url: "https://drillflo.com",
    image: "https://drillflo.com/logo.png",
    offers: {
      "@type": "Offer",
      price: "0.00",
      priceCurrency: "USD",
    },
    softwareVersion: "Beta",
    author: {
      "@type": "Organization",
      name: "DrillFlo Inc.",
    },
    isAccessibleForFree: "true",
    datePublished: "2024-12-30",
  };
  return (
    <div className=" ">
      <Helmet>
        <title>DrillFlo ✨ Marching Band Drill Design Software</title>
        <meta
          name="description"
          content="DrillFlo is the next-generation freemium software for marching band drill designers, enabling modern creators to craft complex, dynamic drill design seamlessly from the cloud."
        />
        <meta
          name="keywords"
          content="marching band, drill design, drill writing, marching arts, cloud-based drill app, DrillFlo, free, professional, indoor guard, indoor percussion, drum corps, drum and bugle corps, "
        />
        <meta name="author" content="DrillFlo LLC" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          property="og:title"
          content="DrillFlo ✨ Marching Band Drill Design Software"
        />
        <meta
          property="og:description"
          content="Craft complex, dynamic marching band formations with DrillFlo, the modern cloud-based app for drill designers."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.drillflo.com" />
        <meta
          property="og:image"
          content="https://www.drillflo.com/og-image.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="DrillFlo ✨ Marching Band Drill Design App"
        />
        <meta
          name="twitter:description"
          content="The next-gen app for marching band drill designers—bring your ideas to life with DrillFlo's cloud-based platform."
        />
        <meta
          name="twitter:image"
          content="https://www.drillflo.com/twitter-image.png"
        />
        <script type="application/ld+json">
          {JSON.stringify(softwareSchema)}
        </script>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>

      <header class="bg-white">
        <div class="mx-auto max-w-screen-xl px-0 sm:px-6 lg:px-0">
          <div class="flex h-16 items-center justify-between">
            <div class="md:flex md:items-center md:gap-12">
              <a class=" text-teal-600 flex flex-row" href="#">
                <span class="sr-only">Home</span>
                {/* <h1 className=" font-black tracking-tighter text-4xl bg-gradient-to-r from-blue-500  to-purple-600 bg-clip-text text-transparent">
                  DrillFlo
                </h1> */}
                <img src="/logo.png" className="w-52" />
                <span className="whitespace-nowrap font-bold rounded-full border-2 border-purple-500 px-2.5  text-sm text-purple-700 h-6 m-auto">
                  BETA
                </span>
              </a>
            </div>

            <div class="hidden md:block">
              <nav aria-label="Global">
                <ul class="flex items-center gap-6 text-sm">
                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="/about"
                    >
                      {" "}
                      About{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="/support"
                    >
                      {" "}
                      Support
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="/blog"
                    >
                      {" "}
                      Blog{" "}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            {/* <div class="flex items-center gap-4">
              <div class="sm:flex sm:gap-4">
                <a
                  class="rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white shadow"
                  href="#"
                >
                  Login
                </a>

                <div class="hidden sm:flex">
                  <a
                    class="rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-teal-600"
                    href="#"
                  >
                    Register
                  </a>
                </div>
              </div>

              <div class="block md:hidden">
                <button class="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </header>
      <section className="bg-gray-100 text-gray-900 shadow-md py-60">
        <div className="mx-auto max-w-screen-xl px-4 py-40 lg:flex lg:h-screen lg:items-center">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl text-gray-500 font-bold animate-slidein opacity-0 [--slidein-delay:300ms]">
              Coming Soon
            </h2>

            <h1 className=" animate-slidein opacity-0 [--slidein-delay:500ms] bg-gradient-to-r  from-blue-500 to-purple-600 bg-clip-text text-transparent pb-4 font-extrabold text-3xl md:text-6xl transition-all ease-in">
              Intuitive Marching Band
              <br /> Drill Design Software.
            </h1>

            <p className="mx-auto mt-12 max-w-xl sm:text-xl/relaxed text-gray-700 animate-slidein opacity-0 [--slidein-delay:500ms]">
              Join the future of Marching Band Drill Design Software.
              Streamlined tools that make every step easier and more connected.
            </p>
            <img
              src="/assets/images/MacbookPreview2.png"
              className="rounded w-[100%] m-auto my-10 animate-slidein opacity-0 [--slidein-delay:600ms] "
              alt=""
            />
            <WaitlistForm />

            {/* <div className="mt-8 flex flex-wrap justify-center gap-4">
              <a
                className="block w-full shadow-lg rounded-full border border-purple-600 bg-purple-600 px-12 py-3 text-sm font-medium text-white hover:text-black hover:bg-transparent  focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                href="/register"
              >
                Get Started
              </a>

              <a
                className="block w-full shadow-lg rounded-full border border-purple-600 px-12 py-3 text-sm font-medium hover:text-white hover:bg-purple-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                href="/login"
              >
                Log In
              </a>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4 md:items-center md:gap-8">
            <div className="md:col-span-3">
              <img
                src="/assets/images/DrillFlo-Demo-1.png"
                className="rounded shadow-lg"
                alt=""
              />
            </div>

            <div className="md:col-span-1">
              <div className="max-w-lg md:max-w-none">
                <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
                  Bring Your Marching Band Drill to Life
                </h2>

                <p className="mt-4 text-gray-700">
                  Design your Marching Band Drill with ease using DrillFlo — the
                  only web-based tool for drill design. Collaborate, innovate,
                  and simplify your process like never before.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-4/5 m-auto h-4/5 my-8 rounded-lg shadow-lg p-8 py-24 ">
        <p className="mt-2 text-pretty text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-balance pb-8">
          Live Demo: Drag, Drop, Design ✨
        </p>
        <DemoViewer />
        <p className="font-semibold w-3/5 m-auto text-xl">
          This isn’t just a video—click and explore how DrillFlo makes drill
          design fast and easy.
        </p>
      </section>
      <section>
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base/7 font-semibold text-blue-600">
                Elevate Your Designs. Empower Your Vision.
              </h2>
              <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
                The Essential Tool for Modern Marching Band Drill Designers.
              </p>
              <p className="mt-6 text-lg/8 text-gray-600">
                As a drill designer for the marching arts, every move, count,
                and pathway starts with your vision. DrillFlo is being crafted
                with you in mind—intuitive, powerful, and designed to help you
                turn your ideas into incredible moments.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16 text-left">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-16">
                    <dt className="text-base/7 font-semibold text-gray-900">
                      <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                        <feature.icon
                          aria-hidden="true"
                          className="h-6 w-6 text-white"
                        />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-base/7 text-gray-600">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-4xl mx-auto p-6">
          <h1 className="text-3xl font-bold text-center mb-6">FAQs</h1>
          <div>
            {faqSchema.mainEntity.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-300 transition-all duration-300 "
              >
                <button
                  className="w-full text-left py-4 px-2 flex justify-between items-center focus:outline-none transition-all duration-300 "
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="text-lg font-medium text-gray-900">
                    {item.name}
                  </span>
                  <span
                    className={`transform ${
                      openIndex === index ? "rotate-180" : "rotate-0"
                    }`}
                  >
                    ▼
                  </span>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ${
                    openIndex === index ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <p className="text-gray-700 px-4 py-2 text-left transition-all duration-300 ">
                    {item.acceptedAnswer.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="mx-auto max-w-screen-xl  py-8 sm:px-6 lg:px-0">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:items-center md:gap-8">
            <div>
              <div className="max-w-lg md:max-w-none">
                <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
                  On the Move.
                </h2>

                <p className="mt-4 text-gray-700">
                  DrillFlo is a browser based Marching Band Drill Design
                  Application, giving you the flexibility to collaborate and
                  work on your production from anywhere.
                </p>
              </div>
            </div>

            <div>
              <img
                src="/assets/images/MacbookPreview.png"
                className="rounded"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="absolute inset-y-0 right-[30%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[70deg] shadow-xl shadow-purple-600/50 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[90%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[80%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="absolute inset-y-0 right-[70%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="absolute inset-y-0 right-[60%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="absolute inset-y-0 right-[50%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/60 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[40%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[30%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="absolute inset-y-0 right-[20%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[10%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <figure className="mt-10">
            <blockquote className="text-center text-xl/8 font-semibold text-gray-900 sm:text-2xl/9">
              <p>
                We’re dedicated to supporting drill designers at every level.
                DrillFlo is being built for the community—get ready to design,
                share, and inspire.
              </p>
            </blockquote>
          </figure>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default MarketingApp;
