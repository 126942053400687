import {
  Cloud,
  Computer,
  Handshake,
  Podcast,
  Rocket,
  SparkleIcon,
} from "lucide-react";
import React from "react";
import Helmet from "react-helmet";
import Footer from "../../components/footer";
import MarketingNavbar from "../../components/marketingNav";
import WaitlistForm from "../../components/waitlist";

const DrillFloOnlineFreeLandingPage = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      <Helmet>
        {/* Basic Meta Tags */}
        <title>
          Free Online Drill Writing Software: Why DrillFlo Stands Out
        </title>
        <meta
          name="description"
          content="Discover why DrillFlo is the top choice for marching band directors and drill writers. A cloud-based, free drill design software that's simple, collaborative, and efficient."
        />
        <meta
          name="keywords"
          content="marching band drill design software, free drill design software, best marching band software, drillflo, cloud-based drill writing"
        />
        <meta name="author" content="DrillFlo" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Why DrillFlo Is the Best Free Drill Design Software for Marching Bands"
        />
        <meta
          property="og:description"
          content="DrillFlo is modern, cloud-based, and collaborative. Perfect for marching band directors and drill writers looking for free and user-friendly drill design software."
        />
        <meta
          property="og:url"
          content="https://drillflo.com/why-best-free-drill-design"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://drillflo.com/static/images/og-image.jpg"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Why DrillFlo Is the Best Free Drill Design Software for Marching Bands"
        />
        <meta
          name="twitter:description"
          content="Discover why DrillFlo is the top choice for band directors and drill writers. Cloud-based, free, and designed for collaboration."
        />
        <meta
          name="twitter:image"
          content="https://drillflo.com/static/images/twitter-image.jpg"
        />

        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://drillflo.com/why-best-free-drill-design"
        />

        {/* Mobile Friendly Meta Tag */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Robots Meta Tag */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <MarketingNavbar />
      {/* Hero Section */}
      <section className="bg-white">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 text-left">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
              Free Online Drill Writing Software: Why DrillFlo Stands Out.
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
              Experience fast, intuitive, and collaborative drill writing for
              marching bands, drum corps, and indoor ensembles from the comfort
              of your browser.
            </p>
            {/* <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-black rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
            >
              Get started
            </a> */}
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="/assets/images/iMacPreview.png" alt="mockup" />
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-12">
        <div className="max-w-5xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">
            What Makes DrillFlo Stand Out?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Feature
              title="No Downloads Necessary"
              description="DrillFlo is a browser based experience, so you don't need to deal with downloads or installing software on your machine."
              icon={<Computer className="m-auto" />}
            />
            <Feature
              title="Cloud-Based Design"
              description="Access your projects anytime, anywhere. Advanced features like collaboration are available in our premium plans."
              icon={<Cloud className="m-auto" />}
            />
            <Feature
              title="Free, Yet Powerful"
              description="DrillFlo's free tier includes essential tools that let you create high-quality drill designs without paying a dime. You can upgrade for advanced features when you are ready."
              icon={<Rocket className="m-auto" />}
            />{" "}
            <Feature
              title="Always the Latest and Greatest Version "
              description="Because DrillFlo is a web based tool, you never need to update or fall behind on the latest features or functionality. DrillFlo updates and constantly gets better."
              icon={<Podcast className="m-auto" />}
            />
            <Feature
              title="Collaboration Tools (Premium)"
              description="Upgrade to unlock real-time collaboration, commenting, and more for seamless team workflows."
              icon={<Handshake className="m-auto text-gray-400" />}
            />
            <Feature
              title="Beginner-Friendly Interface"
              description="Simple, intuitive tools designed for everyone, from first-time designers to seasoned pros. Learn how to design Marching Band Drill on one tool and upgrade as you grow!"
              icon={<SparkleIcon className="m-auto" />}
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-blue-600 text-white py-12">
        <div className="max-w-5xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Change the Way You Design Drill?
          </h2>
          <p className="text-lg font-light mb-6">
            Start with DrillFlo's free tier and explore our essential tools for
            creating drill charts. Upgrade to unlock powerful premium features
            like real-time collaboration and advanced editing tools.
          </p>
          <p className="text-black">
            <WaitlistForm />
          </p>
          <p className="py-4 text-sm">No credit card required to start.</p>
        </div>
      </section>

      {/* Testimonials Section */}
      {/* <section className="py-12 bg-gray-100">
        <div className="max-w-5xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">What Our Users Are Saying</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Testimonial
              quote="DrillFlo made drill writing so much easier! The interface is clean, and the collaboration tools are a game-changer."
              name="Alex M."
              role="High School Band Director"
            />
            <Testimonial
              quote="As someone new to drill design, DrillFlo was incredibly easy to learn. I had my first chart done in hours!"
              name="Taylor R."
              role="College Student"
            />
            <Testimonial
              quote="Finally, a drill design tool that doesn't break the bank. DrillFlo has everything I need for my work."
              name="Chris L."
              role="Professional Drill Writer"
            />
          </div>
        </div>
      </section> */}
      <section>
        <div class="overflow-x-auto w-3/5 m-auto py-8">
          <table class="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead>
              <tr class="bg-gray-100">
                <th class="px-4 py-2 text-left text-gray-600 font-bold">
                  Feature
                </th>
                <th class="px-4 py-2 text-center text-gray-600 font-bold">
                  Included
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- Feature 1 --> */}

              {/* <!-- Feature 2 --> */}
              <tr class="bg-gray-50">
                <td class="px-4 py-2 border-t text-gray-700">
                  Music Integration (MusicXML)
                </td>
                <td class="px-4 py-2 border-t text-center text-green-600 font-bold">
                  ✔
                </td>
              </tr>
              {/* <!-- Feature 3 --> */}
              <tr>
                <td class="px-4 py-2 border-t text-gray-700">
                  Cloud-Based Access
                </td>
                <td class="px-4 py-2 border-t text-center text-green-600 font-bold">
                  ✔
                </td>
              </tr>
              <tr>
                <td class="px-4 py-2 border-t text-gray-700">
                  Browser Based App
                </td>
                <td class="px-4 py-2 border-t text-center text-green-600 font-bold">
                  ✔
                </td>
              </tr>

              {/* <!-- Feature 4 --> */}
              <tr class="bg-gray-50">
                <td class="px-4 py-2 border-t text-gray-700">Export to PDF</td>
                <td class="px-4 py-2 border-t text-center text-green-600 font-bold">
                  ✔
                </td>
              </tr>
              {/* <!-- Feature 5 --> */}
              <tr>
                <td class="px-4 py-2 border-t text-gray-700">Field Types</td>
                <td class="px-4 py-2 border-t text-center text-green-600 font-bold">
                  ✔
                </td>
              </tr>
              {/* <!-- Feature 6 --> */}
              <tr class="bg-gray-50">
                <td class="px-4 py-2 border-t text-gray-700">
                  Fast and Modern Design
                </td>
                <td class="px-4 py-2 border-t text-center text-green-600 font-bold">
                  ✔
                </td>
              </tr>
              {/* <!-- Feature 7 --> */}
              <tr>
                <td class="px-4 py-2 border-t text-gray-700">
                  Affordable Pricing
                </td>
                <td class="px-4 py-2 border-t text-center text-green-600 font-bold">
                  ✔ (Premium)
                </td>
              </tr>
              <tr>
                <td class="px-4 py-2 border-t text-gray-700">
                  Real-Time Collaboration
                </td>
                <td class="px-4 py-2 border-t text-center text-green-600 font-bold">
                  ✔ (Premium)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* Footer */}
      <Footer />
    </div>
  );
};

// Reusable Feature Component
const Feature = ({ icon, title, description }) => (
  <div className="bg-white shadow-lg p-6 rounded-lg">
    <div className="text-4xl mb-4  m-auto w-full ">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

// Reusable Testimonial Component
const Testimonial = ({ quote, name, role }) => (
  <div className="bg-white shadow-lg p-6 rounded-lg">
    <p className="text-gray-800 italic mb-4">&ldquo;{quote}&rdquo;</p>
    <p className="text-gray-600 font-semibold">{name}</p>
    <p className="text-gray-500 text-sm">{role}</p>
  </div>
);

export default DrillFloOnlineFreeLandingPage;
