import MarketingNavbar from "../../components/marketingNav";
import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import { getDocs, collection, orderBy, query } from "firebase/firestore"; // Import necessary functions from Firebase
import Footer from "../../components/footer";

export default function BlogHome() {
  const [blogs, setBlogs] = useState([]);
  const [featuredBlog, setFeaturedBlog] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const q = query(collection(db, "blogs"), orderBy("date_added", "desc")); // Assuming "timestamp" is the field for the blog date
        const querySnapshot = await getDocs(q);
        const blogsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogsList);
        setFeaturedBlog(blogsList[0]); // Set the most recent blog as the featured blog
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <MarketingNavbar />
      <div className="py-4 bg-base-100 text-center font-work w-4/8 m-auto max-w-screen-xl">
        <h1 className="text-base-content text-3xl font-semibold">
          DrillFlo Blog
        </h1>
        <div className="text-base breadcrumbs text-base-content/80 font-work mt-2 flex items-center justify-center">
          <ul>
            <li>
              <a
                href={`/`}
                className="hover:text-primary transition hover:duration-300 font-medium text-base"
              >
                Home
              </a>
            </li>
          </ul>
        </div>

        {/* Featured Blog Section */}
        {featuredBlog && (
          <section className="mt-12 text-left">
            <div className="card relative font-work">
              <figure>
                <img
                  // width="1216"
                  height="450"
                  alt="banner image"
                  src={featuredBlog.image}
                  className="rounded-xl w-f"
                />
              </figure>
              <div className="card-body p-2 md:p-10 absolute bottom-0 w-full md:w-8/12 z-20">
                <div className="w-fit text-white px-2.5 py-1 bg-primary text-xs md:text-sm rounded-md mb-2 md:mb-4 font-medium">
                  {featuredBlog.category}
                </div>
                <h3>
                  <a
                    href={`/blog/${featuredBlog.path}`}
                    className="text-neutral-content text-white font-semibold text-xl md:text-2xl lg:text-4xl leading-5 md:leading-10 hover:text-primary transition hover:duration-500"
                  >
                    {featuredBlog.title}
                  </a>
                </h3>
                <div className="mt-3 md:mt-6 flex items-center gap-5 text-neutral-content">
                  <div className="flex items-center gap-3">
                    <div className="avatar"></div>
                    <h5>
                      <a
                        href="./author/index.html"
                        className="text-xs md:text-base text-white font-medium hover:text-primary transition hover:duration-300"
                      >
                        {featuredBlog.author}
                      </a>
                    </h5>
                  </div>
                  <p className="text-xs md:text-base">
                    {" "}
                    {featuredBlog.date_added.toDate().toDateString()}
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Other Blogs Section */}
        <section className="mt-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <div class="card w-fit p-4 border border-base-content/10 rounded-xl font-work text-left">
                  <figure>
                    <img
                      src={blog.image}
                      alt="email"
                      class="rounded-xl"
                      width="{360}"
                      height="{240}"
                    />
                  </figure>
                  <div class="card-body py-6 px-2">
                    <span class="btn no-animation hover:bg-primary hover:text-primary-content bg-primary/5 border-0 text-primary text-sm px-3 py-2 min-h-fit h-fit rounded-md w-fit capitalize font-medium">
                      {blog.category}
                    </span>
                    <h3>
                      <a
                        href={/blog/ + blog.path}
                        class="text-base-content hover:text-primary transition-all duration-300 ease-in-out font-semibold text-lg md:text-xl lg:text-2xl mt-2"
                      >
                        {blog.title}
                      </a>
                    </h3>
                    <div class="mt-5 flex items-center gap-5 text-base-content/60">
                      <div class="flex items-center gap-3">
                        <h5>
                          <a
                            href="./author/index.html"
                            class="text-base font-medium hover:text-primary transition hover:duration-300"
                          >
                            {blog.author}
                          </a>
                        </h5>
                      </div>
                      <p class="text-base">
                        {" "}
                        {blog.date_added.toDate().toDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </section>

        {/* Load More Button */}
        {/* <div className="flex items-center justify-center w-full mt-8">
          <a
            href="#"
            className="btn btn-outline btn-secondary text-secondary-content/60 font-work font-medium text-base"
          >
            Load More
          </a>
        </div> */}

        {/* Advertisement Section */}
        {/* <section className="mt-20 flex items-center justify-center">
          <div className="w-11/12 lg:w-8/12">
            <div className="py-4 bg-base-content/10 text-base-content/60 text-center rounded-xl font-work">
              <p className="text-sm">Advertisement</p>
              <p className="text-xl font-semibold leading-6">
                You can place ads
              </p>
              <p className="text-lg leading-[26px]">750x100</p>
            </div>
          </div>
        </section> */}
      </div>
      <Footer />
    </>
  );
}
